import { ModalService } from '@core/Services/modal.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-home-admin',
  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.scss']
})
export class HomeAdminComponent implements OnInit, AfterViewInit {
  childComponent;
  router: string;
  opened = true;
  @ViewChild(RouterOutlet, { static: false }) outlet;

  constructor(private _router: Router, private location: Location, private modal: ModalService) {
    _router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.router = val.url;
      }
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if(this.outlet && this.outlet.component) {
      this.childComponent = this.outlet.component;
    }
  }

  openModal(modal) {
    switch(modal) {
      case "organizers": {
        this.modal.openOrganizerAdd().subscribe((res: any) => {
          if(!res || res === undefined || res === null) {
            return;
          }

          this.childComponent.refresh();
        });
        break;
      }
      case "runners": {
        this.modal.openRunnerAdd().subscribe((res: any) => {
          if(!res || res === undefined || res === null) {
            return;
          }

          this.childComponent.refresh();
        });
        break;
      }
      case "teams": {
        this.modal.openCaptainAdd().subscribe((res: any) => {
          if(!res || res === undefined || res === null) {
            return;
          }

          this.childComponent.refresh();
        });
        break;
      }
      case "races": {
        this.modal.openRaceAdd().subscribe((res: any) => {
          if(!res || res === undefined || res === null) {
            return;
          }

          this.childComponent.refresh();
        });
        break;
      }
      case "reservations": {
        this.modal.openReservationAdd().subscribe((res: any) => {
          if(!res || res === undefined || res === null) {
            return;
          }

          this.childComponent.refresh();
        });
        break;
      }
    }
  }

  backButton() {
    this.location.back();
  }
}
