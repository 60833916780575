import { IInventory } from './../../../core/Models/IInventory';
import { FormErrorStateMatcher } from './../../registration/registration.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavigationEnd } from '@angular/router';
import { InventoryService } from './../../../core/Services/Data/inventory.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, ViewChild, Input, AfterViewInit } from '@angular/core';



@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit, AfterViewInit {
  activeTitle = '';
  type = "Akontacija";
  public items = [0];
  saved = false;
  empty = true;
  matcher;
  periods = new Subject<string>();
  public inventoryAdd: FormGroup;
  selectedPeriods = {
    data: []
  };
  @ViewChild('name', { static: false }) name: any;
  @ViewChild('desc', { static: false }) desc: any;
  @Input() title: string;
  @Input() raceId: number;
  @Input() raceDates: Observable<Array<any>>;
  @Output() inventories = new EventEmitter<Object>();

  constructor(private inventoryService: InventoryService, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.activeTitle = this.title;
    this.validation();

    // this.raceDates.subscribe(val => {

    // });
  }

  ngAfterViewInit() {
    if (this.raceId) {
      this.loadData(this.raceId);
    }
  }

  private async loadData(raceId) {
    const result = await this.inventoryService.getAllIntervals(raceId);
    this.populate(result);
  };

  private populate(data: IInventory) {
    this.selectedPeriods['inventoryId'] = data.id;
    this.activeTitle = data.type;
    this.form.name.setValue(data.name);
    this.form.desc.setValue(data.description);

    if(data && data.intervals) {
      this.items = data.intervals as any;
      this.selectedPeriods.data = data.intervals;
      this.empty = false;
      this.saved = true;
    }
  }

  get form() { return this.inventoryAdd.controls; }

  private validation() {
    this.inventoryAdd = this.formBuilder.group({
      name: ['', Validators.required],
      desc: ['', Validators.required],
    });

    this.matcher = new FormErrorStateMatcher();
  }

  public add(e) {
    e.preventDefault();
    if (this.items.length == 0 || this.selectedPeriods.data.length == 1)
    {
      this.saved = false;
    }
    this.items = [...this.items, this.items.length];
  }

  public remove(e) {
    e.preventDefault();
    if (this.items.length == this.selectedPeriods.data.length) {
      this.items.pop();
      this.selectedPeriods.data.pop();
      if (this.items.length == 0)
      {
        this.empty = true;
      }
    }
    if (this.items.length > this.selectedPeriods.data.length) {
      this.items.pop();
    }

    this.inventories.emit(this.selectedPeriods);
  }

  public update(e) {
    if(e.srcElement.name == 'nameInput')
    {
      this.selectedPeriods['name'] = e.srcElement.value;
    }

    if(e.srcElement.name == 'descInput')
    {
      this.selectedPeriods['desc'] = e.srcElement.value;
    }
    // this.inventories.emit(this.selectedPeriods);
  }

  selectPeriods($event) {
    let index: any | null = this.selectedPeriods.data.findIndex(period => period.inputPeriod === $event.inputPeriod);

    if(index === null || index === undefined || index === -1) {
      this.selectedPeriods.data.push($event);
    }

    if(index !== -1 && index !== undefined) {
      this.selectedPeriods.data[index] = $event;
    }

    this.selectedPeriods['desc'] = this.desc.nativeElement.value;
    this.selectedPeriods['type'] = this.type;
    this.selectedPeriods['name'] = this.name.nativeElement.value;
    this.periods.next($event);
    this.saved = true;
    this.empty = false;
    this.inventories.emit(this.selectedPeriods);
  }

  ngOnDestroy() {
    this.periods.complete();
  }
}
