import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

  @Output() fileToUpload = new EventEmitter<any>();

  @HostBinding('style.background-color') private background = '#FFFF';
  @HostBinding('style.opacity') private opacity = '1';

  @HostListener('dragover', ['$event']) public onDragOver($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.background = '#ABE0F9';
    this.opacity = '0.5'
  }

  @HostListener('dragleave', ['$event']) public onDragLeave($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.background = '#FFFF';
    this.opacity = '1';
  }

  @HostListener('drop', ['$event']) public onDrop($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.background = '#FFFF';
    this.opacity = '1';
    let file = $event.dataTransfer.files;
    if(file.length > 0) {
      this.fileToUpload.emit(file);
    }
  }

}
