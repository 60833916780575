import { ILoginRequest } from '@core/Models/Requests/ILoginRequest';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilityService } from './utility.service';
import { IAdminLoginRequest } from '@core/Models/Requests/IAdminLoginRequest';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private util: UtilityService,
    private http: HttpClient,
    )
  {  }

  login(data: ILoginRequest) {
    return this.http.post(this.util.baseUrl + 'login', {...data});
  }
  loginAdmin(data: IAdminLoginRequest) {
    return this.http.post(this.util.baseUrl + 'admin', {...data})
  }
  logout(data) {
    return this.http.post(this.util.baseUrl + 'logout', {...data});
  }
  register(data) {
    return this.http.post(this.util.baseUrl + 'register', {...data});
  }

  // ORGANIZER
  getAllOrganizers() {
    return this.http.get(this.util.baseUrl + 'organizers/all');
  }
  editOrganizer(organizer) {
    return this.http.put(this.util.baseUrl + 'organizers/edit', {...organizer});
  }
  deleteOrganizer(organizer) {
    return this.http.delete(this.util.baseUrl + 'organizers/delete/' + organizer.id );
  }
  addNewOrganizer(organizer) {
    return this.http.post(this.util.baseUrl + 'organizers/add', {...organizer});
  }
  getCurrentOrganizer() {
    return this.http.get(this.util.baseUrl + 'organizers/current');
  }
  getOrganizerById(organizerId) {
    return this.http.get(this.util.baseUrl + 'organizers/' + organizerId);
  }
  uploadOrganizerLogo(data) {
    let options = { content: data };
    return this.http.post(this.util.baseUrl + 'organizers/logo/upload', {...options});
  }
  getOrganizerLogo() {
    return this.http.get(this.util.baseUrl + 'organizers/logo');
  }
  getOrganizerContact() {
    return this.http.get(this.util.baseUrl + 'organizers/info');
  }

  // RUNNERS
  getAllRunners() {
    return this.http.get(this.util.baseUrl + 'runners/all');
  }
  getAllRunnersByRaceId(raceId) {
    return this.http.get(this.util.baseUrl + 'runners/race/' + raceId);
  }
  getAvailableRunnersByCaptainId(captainId, raceId) {
    let params = new HttpParams();
    params = params.append('captainId', captainId);
    params = params.append('raceId', raceId);
    return this.http.get(this.util.baseUrl + 'runners/captain/available', { params: params });
  }
  getAllRunnersByReservationId(reservationId) {
    return this.http.get(this.util.baseUrl + 'runners/reservation/' + reservationId);
  }
  addNewRunner(runner) {
    return this.http.post(this.util.baseUrl + 'runners/add',  {...runner});
  }
  editRunner(runner) {
    return this.http.put(this.util.baseUrl + 'runners/edit', {...runner});
  }
  registerRunner(runner) {
    return this.http.post(this.util.baseUrl + 'runners/register', {...runner});
  }
  addRunnersToReservation(data) {
    return this.http.post(this.util.baseUrl + 'runners/reservations', {...data});
  }
  deleteRunner(runnerId) {
    return this.http.delete(this.util.baseUrl + 'runners/delete/' + runnerId);
  }

  // RACE
  getRaceById(raceId) {
    return this.http.get(this.util.baseUrl + 'races/' + raceId);
  }
  getAllRaces() {
    return this.http.get(this.util.baseUrl + 'races/all');
  }
  getAllRacesByOrganizer() {
    return this.http.get(this.util.baseUrl + 'races/organizer/all');
  }
  addNewRace(race) {
    return this.http.post(this.util.baseUrl + 'races/add', {...race})
  }
  editRace(race) {
    return this.http.put(this.util.baseUrl + 'races/edit', {...race});
  }
  lockRace(race) {
    return this.http.put(this.util.baseUrl + 'races/lock', {...race});
  }
  getAvailableRaces() {
    return this.http.get(this.util.baseUrl + 'races/available');
  }
  getEntryList(raceId) {
    return this.http.get(this.util.baseUrl + 'races/roster/' + raceId);
  }
  getRaceLogo(raceId) {
    return this.http.get(this.util.baseUrl + 'races/logo/' + raceId);
  }

  // CAPTAIN
  getCaptainById(captainId) {
    return this.http.get(this.util.baseUrl + 'captains/' + captainId);
  }
  getCurrentCaptain() {
    return this.http.get(this.util.baseUrl + 'captains/current');
  }
  getAllTeams() {
    return this.http.get(this.util.baseUrl + 'captains/all');
  }
  getAllTeamsByRaceId(raceId) {
    return this.http.get(this.util.baseUrl + 'captains/race/' + raceId);
  }
  getAllTeamsByOrganizerId(organizerId) {
    return this.http.get(this.util.baseUrl + 'captains/organizer/' + organizerId);
  }
  addNewCaptain(captain) {
    captain['subdomain'] = this.util.getSubdomain();
    return this.http.post(this.util.baseUrl + 'captains/add', {...captain});
  }
  editCaptain(captain) {
    return this.http.put(this.util.baseUrl + 'captains/edit', {...captain});
  }
  deleteCaptain(captainId) {
    return this.http.delete(this.util.baseUrl + 'captains/delete/' + captainId);
  }


  // RESERVATION
  getReservationById(reservationId) {
    return this.http.get(this.util.baseUrl + 'reservations/' + reservationId);
  }
  getAllReservations() {
    return this.http.get(this.util.baseUrl + 'reservations/all');
  }
  getAllReservationsByRaceId(raceId: any) {
    return this.http.get(this.util.baseUrl + 'reservations/race/' + raceId);
  }
  addNewReservation(data){
    return this.http.post(this.util.baseUrl + 'reservations/add', {...data});
  }
  editReservation(reservation) {
    return this.http.put(this.util.baseUrl + 'reservations/edit', {...reservation});
  }
  deleteReservation(reservationId) {
    return this.http.delete(this.util.baseUrl + 'reservations/delete/' + reservationId);
  }
  deleteRunnerFromReservation(runnerId, reservationId) {
    return this.http.delete(this.util.baseUrl + 'reservations/runners/delete/' + runnerId + '/' + reservationId)
  }
  setPaymentStatus(reservationId) {
    return this.http.patch(this.util.baseUrl + 'reservations/paid', { reservationId: reservationId });
  }
  getTotalRunners(reservationId) {
    return this.http.get(this.util.baseUrl + 'reservations/' + reservationId + '/runners/total')
  }

  // INVENTORY
  getInventoryWithIntervals(raceId, reservation) {
    let params = new HttpParams();
    params = params.append('reservation', reservation);
    return this.http.get(this.util.baseUrl + 'inventory/race/' + raceId, { params: params });
  }
  getInventoryWithAllIntervals(raceId) {

    return this.http.get(this.util.baseUrl + 'inventory/intervals/' + raceId);
  }


  // LOOKUPS
  getShirtSizeLookups() {
    return this.http.get(this.util.baseUrl + 'lookups/' + 'shirtSize');
  }
  getCaptainLookups() {
    return this.http.get(this.util.baseUrl + 'lookups/' + 'captain');
  }
  getSexLookups() {
    return this.http.get(this.util.baseUrl + 'lookups/' + 'sex');
  }
  getWelcomeMessage(captainId) {
    let params = new HttpParams();
    params = params.append('captainId', captainId);
    return this.http.get(this.util.baseUrl + 'lookups/' + 'welcome', { params: params });
  }
  getRaceName(raceId) {
    let params = new HttpParams();
    params = params.append('raceId', raceId);
    return this.http.get(this.util.baseUrl + 'lookups/' + 'race-name', { params: params });
  }
  getTeamName(captainId) {
    let params = new HttpParams();
    params = params.append('captainId', captainId);
    return this.http.get(this.util.baseUrl + 'lookups/' + 'team-name', { params: params });
  }
  getMultipleLookups(lookups: string) {
    // lookups example: 'shirtSize,captain,sex' typeof string
    let params = new HttpParams();
    params = params.append('lookups', lookups);
    return this.http.get(this.util.baseUrl + 'lookups', { params: params });
  }


  // TEMPLATE
  addNewTemplate(template) {
    return this.http.post(this.util.baseUrl + 'template/save', {...template});
  }
  getExportedTemplate(data) {
    return this.http.post(this.util.baseUrl + 'export/pdf', {...data});
  }
  getTemplate(type) {
    let params = new HttpParams();
    params = params.append('type', type);
    return this.http.get(this.util.baseUrl + 'template', { params: params });
  }


  // DASHBOARD
  getDashboardData() {
    return this.http.get(this.util.baseUrl + 'dashboard');
  }

  // SETTINGS
  checkOldPasswordValidity(data) {
    return this.http.post(this.util.baseUrl + 'settings/password', {...data});
  }
  changePassword(data) {
    return this.http.post(this.util.baseUrl + 'settings/password/change', {...data});
  }
}
