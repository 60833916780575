import { SettingsService } from './../../../core/Services/Data/settings.service';
import { FormErrorStateMatcher } from './../../registration/registration.component';
import { SnackbarService } from './../../../core/Services/snackbar.service';
import { UtilityService } from '@core/Services/utility.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {
  matcher;
  public passwordChange: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private snackBarService: SnackbarService,
    private util: UtilityService,
    private settingsService: SettingsService) { }

  ngOnInit() {
    this.validation();
  }

  private validation() {
    this.passwordChange = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      password: ['', Validators.required],
      passwordConfirmation: ['', Validators.required],
    });

    this.matcher = new FormErrorStateMatcher();
  }

  get form() {
    if(this.passwordChange && this.passwordChange.controls) {
      return this.passwordChange.controls;
    }
  }

  public async checkOldPassword(inputValue) {

    if(inputValue == "") {
      return;
    }

    const isValid = await this.settingsService.checkOldPasswordValidity(inputValue);

    if(isValid.data) {
      if(this.form.oldPassword.errors !== undefined) {
        this.form.oldPassword.setErrors(null);
      }
    } else {
      this.form.oldPassword.setErrors({ notValid: true });
    }
  }

  checkPasswordValidity() {
    if (this.form.password.value !== this.form.passwordConfirmation.value) {
      this.form.password.setErrors({ noMatch: true });
      this.form.passwordConfirmation.setErrors({ noMatch: true });
      return;
    }

    this.form.password.setErrors(null);
  }

  public async onClickSubmit(value) {

    if(!value.oldPassword && !value.password && !value.passwordConfirmation) {
      return;
    }

    let result: any = await this.settingsService.changePassword(value.oldPassword, value.password, value.passwordConfirmation);

    if(result && result.errors.length > 0)
    {
      this.snackBarService.open('Nešto je pošlo po krivu. Probajte ponovno učitati stranicu!');
    }

    if(result && result.data)
    {
      this.snackBarService.open('Uspješno ste promijenili šifru.');

      this.form.oldPassword.setValue(null);
      this.form.password.setValue(null);
      this.form.passwordConfirmation.setValue(null);

      this.form.oldPassword.setErrors(null);
      this.form.password.setErrors(null);
      this.form.passwordConfirmation.setErrors(null);
    }

    return;
  }

}
