import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/Services/authentication.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  public title = 'BusinessRun';
  public loggedIn = false;
  public route;

  constructor(private auth: AuthenticationService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.subscribe((val) => {
      this.isLoggedIn();
      if (val instanceof NavigationEnd) {
        this.route = val.url;
        let path = val.url.substring(0, 10);
        if(path == "/microsite") {
          this.route = path;
          this.loggedIn = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.isLoggedIn();
  }

  ngAfterViewInit() {
  }

  private isLoggedIn() {
    if(this.route !== '/microsite') {
      this.loggedIn = this.auth.loggedIn();
    }
  }

  public logout() {
    this.auth.logout();
    this.loggedIn = false;
  }

  public register() {
    this.router.navigate(['registration']);
  }
}

