import { AuthenticationService } from '@core/services/authentication.service';
import { UtilityService } from '@core/Services/utility.service';
import { SnackbarService } from './../../core/Services/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { RacesService } from '@core/Services/Data/races.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-entry-list',
  templateUrl: './entry-list.component.html',
  styleUrls: ['./entry-list.component.scss']
})
export class EntryListComponent implements OnInit {
  state: any;
  selectedRunners: any = [];
  public setColumns: string[] = [
    'position',
    'resId',
    'runnerRoster',
    'teamNameRoster',
    'companyNameRoster',
    'captainRoster',
    'paymentStatusRoster',
  ];

  constructor(private raceService: RacesService, private snackbarService: SnackbarService, private util: UtilityService,
    private router: Router, private auth: AuthenticationService)
  {
    const navigation = this.router.getCurrentNavigation();
    if(navigation.extras.state === undefined) {
      let role = this.auth.getRole();
      let route = role + '/races';
      this.router.navigate([route]);
    } else {
      this.state = navigation.extras.state;
      this.loadData(this.state.id);
    }
  }

  ngOnInit() {
  }

  private async loadData(raceId) {
    let result = await this.raceService.getEntryList(raceId);

    if(result && result.errors.length > 0) {
      this.snackbarService.open('Nešto je pošlo po krivu. Probajte ponovno učitati stranicu!');
    }

    if(result && !this.util.isEmptyObject(result.data)) {
      let runners: any = [];
      let reservations: any = result.data;
      reservations.forEach(reservation => {

        reservation.runners.forEach(runner => {
          runners.push({
            reservationId: reservation.id,
            runnerName: runner.name,
            runnerLastName: runner.last_name,
            name: reservation.captain.name,
            lastName: reservation.captain.last_name,
            teamName: reservation.captain.team_name,
            companyName: reservation.captain.company_name,
            paymentStatus: reservation.paymentStatus
          });
        });

        //  empty (reserved) spaces
        let remaining = reservation.reservedPlaces - reservation.runners.length;
        for (let index = 0; index < remaining; index++) {
          runners.push({
            reservationId: reservation.id,
            runnerName: '',
            runnerLastName: '',
            name: reservation.captain.name,
            lastName: reservation.captain.last_name,
            teamName: reservation.captain.team_name,
            companyName: reservation.captain.company_name,
            paymentStatus: reservation.paymentStatus
          });
        }
      });

      this.selectedRunners = runners;
    }
  }

}
