import { IResult } from '@core/Models/IResult';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '@core/Services/authentication.service';
import { UtilityService } from '@core/Services/utility.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private api: ApiService, private util: UtilityService, private auth: AuthenticationService) {
    this.init();
  }

  init() {
    this.auth.isAuthorized();
  }

  public async getDashboardData() {
    let dashboard: IResult = {
      data: {},
      errors: []
    };

    try {
      dashboard.data = await this.api.getDashboardData().pipe(first()).toPromise();
    } catch (e) {
      dashboard.errors = [e.error];
    }

    dashboard.data = this.util.keysToCamelCase(dashboard.data);

    Object.keys(dashboard.data).forEach((index: any) => {
      dashboard.data[index].reservations = this.util.keysToCamelCase(dashboard.data[index].reservations);
    });

    return dashboard;
  }
}
