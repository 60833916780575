import { IResult } from '@core/Models/IResult';
import { AuthenticationService } from './../authentication.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { UtilityService } from '../utility.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private api: ApiService, private util: UtilityService, private auth: AuthenticationService) {
    this.init();
  }

  init() {
    this.auth.isAuthorized();
  }

  public async getIntervals(raceId, reservation = null) {
    let inventory: IResult = {
      data: {},
      errors: []
    };

    if(reservation)
    {
      reservation = this.util.encodeURIData(reservation);
    }

    try {
      inventory.data = await this.api.getInventoryWithIntervals(raceId, reservation).pipe(first()).toPromise();
    } catch (e) {
      inventory.errors = e.error;
    }

    inventory.data = this.util.keysToCamelCase(inventory.data);
    return inventory;
  }

  public async getAllIntervals(raceId) {
    let inventory;

    try {
      inventory = await this.api.getInventoryWithAllIntervals(raceId).pipe(first()).toPromise();
      if (inventory.length === 0) {
        inventory = { errors: 'There are no inventory.' };
      }
    } catch (e) {
      inventory = e.error;
    }

    inventory = this.util.keysToCamelCase(inventory);
    inventory.intervals = this.util.keysToCamelCase(inventory.intervals);
    return inventory;
  }
}
