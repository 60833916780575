import { first } from 'rxjs/operators';
import { AuthenticationService } from './../authentication.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { UtilityService } from '../utility.service';
import { IResult } from '@core/Models/IResult';
import { ICaptain } from '@core/Models/ICaptain';

@Injectable({
  providedIn: 'root'
})
export class CaptainsService {

  constructor(private api: ApiService, private util: UtilityService, private auth: AuthenticationService) {
    this.init();
  }

  init() {
    this.auth.isAuthorized();
  }

  public async addNewCaptain(data) {
    let captain: IResult = {
      data: {},
      errors: []
    };

    try {
      captain.data = await this.api.addNewCaptain(data).pipe(first()).toPromise();
    } catch (e) {
      captain.errors = [e.error];
    }

    return captain;
  }

  public async getById(captainId): Promise<IResult> {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.getCaptainById(captainId).pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    result.data = this.util.keysToCamelCase(result.data);
    return result;
  }

  public async editCaptain(Captain): Promise<IResult> {
    let result: IResult = {
      data: {},
      errors: [],
    };

    try {
      result.data = await this.api.editCaptain(Captain).pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    return result;
  }


  public async getAllTeams(){
    let teams: IResult = {
      data: {},
      errors: []
    };

    try {
      teams.data = await this.api.getAllTeams().pipe(first()).toPromise();
    } catch (e) {
      teams.errors = [e.error];
    }

    teams.data = this.util.keysToCamelCase(teams.data);
    return teams;
  }

  public async getAllTeamsByRaceId(race: any) {
    let teams: IResult = {
      data: {},
      errors: []
    };

    try {
      teams.data = await this.api.getAllTeamsByRaceId(race.id).pipe(first()).toPromise();
    } catch (e) {
      teams.errors = [e.error];
    }

    teams.data = this.util.keysToCamelCase(teams.data);
    return teams;
  }

  public async getAllTeamsByOrganizerId(organizerId: any) {
    let teams;

    try {
      teams = await this.api.getAllTeamsByOrganizerId(organizerId).pipe(first()).toPromise();
      if (teams.length === 0) {
        teams = { errors: 'There are no teams.'};
      }
    } catch (e) {
      teams = e.error;
    }

    teams = this.util.keysToCamelCase(teams);
    return teams;
  }

  async deleteCaptain(captainId) {
    let captain: IResult = {
      data: {},
      errors: []
    };

    try {
      captain.data = await this.api.deleteCaptain(captainId).pipe(first()).toPromise();
    } catch (e) {
      captain.errors = e.error;
    }

    captain.data = this.util.keysToCamelCase(captain.data);

    return captain;
  }

  async getCurrentCaptain() {
    let captain: IResult = {
      data: {},
      errors: []
    };

    try {
      captain.data = await this.api.getCurrentCaptain().pipe(first()).toPromise();
    } catch (e) {
      captain.errors = e.error;
    }

    captain.data = this.util.keysToCamelCase(captain.data);

    return captain;
  }
}
