import { UtilityService } from '@core/Services/utility.service';
import { ICaptain } from './../../../core/Models/ICaptain';
import { CaptainsService } from './../../../core/Services/Data/captains.service';
import { ModalService } from './../../../core/Services/modal.service';
import { SettingsService } from './../../../core/Services/Data/settings.service';
import { IResult } from './../../../core/Models/IResult';
import { SnackbarService } from '@core/Services/snackbar.service';
import { IRunner } from '@core/Models/IRunner';
import { RunnersService } from '@core/Services/Data/runners.service';
import { AuthenticationService } from '@core/Services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InventoryService } from '@core/Services/Data/inventory.service';
import { RacesService } from '@core/Services/Data/races.service';
import { IRace } from '@core/Models/IRace';
import { IInventory, IIntervals } from '@core/Models/IInventory';
import { ReservationsService } from '@core/Services/Data/reservations.service';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.scss']
})
export class ReservationDetailComponent implements OnInit {
  state: any | undefined;
  race: IRace;
  inventory: IInventory;
  runners: IRunner[];
  interval: IIntervals;
  captain: ICaptain;
  totalAmount;
  microsite;
  public setColumns: string[] = [
    'position',
    'name',
    'lastName',
    'sex',
    'phone',
    'shirtSize',
    'unlink'
  ];


  constructor(private router: Router, private inventoryService: InventoryService, private raceService: RacesService,
               private runnerService: RunnersService, private auth: AuthenticationService, private snackbarService: SnackbarService,
               private settingsService: SettingsService, private dialogService: ModalService, private reservationsService: ReservationsService,
               private captainService: CaptainsService, private util: UtilityService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if(navigation.extras.state === undefined) {
      let role = this.auth.getRole();
      let route = role + '/reservations';
      this.router.navigate([route]);
    } else {
      this.state = navigation.extras.state;
    }
  }

  ngOnInit() {
    this.loadData(this.state.raceId);
  }

  private async loadData(raceId) {
    const races = await this.raceService.getById(raceId);
    const runners = await this.runnerService.getRunnersByReservation(this.state.id);
    const inventory: any = await this.inventoryService.getIntervals(raceId, this.state);
    const captain = await this.captainService.getById(this.state.captainId);

    this.captain = captain.data as ICaptain;
    this.race = races.data as IRace;
    this.runners = runners.data as IRunner[];
    this.inventory = inventory.data as IInventory;

    let totalRunners: any = await this.reservationsService.getTotalRunners(this.state.id);
    this.race.totalRunners = totalRunners.errors.length > 0 ? 0 : totalRunners.data;

    if(inventory && inventory.data && (inventory.data.intervals !== null))
    {
      this.interval = inventory.data.intervals;
      this.totalAmount = this.state.reservedPlaces * this.interval.price;
    }

    let data = {
      reservationId: this.state.id,
      captainId: this.state.captainId,
      raceId: this.state.raceId
    };

    let hash = btoa(JSON.stringify(data));
    this.microsite = this.util.getUrl() + "/microsite/" + hash;
  }

  isPaid() {
    return this.state.paymentStatus ? 'primary' : 'warn';
  }

  copyToClipboard(element) {
    let result = this.util.copyTextToClipboard(element.innerText);
  }

  public async billing(type) {
    let result: IResult;

    result = await this.settingsService.getExportedTemplate(type, this.state);

    if(result && result.errors.length > 0) {
      this.snackbarService.open('Ne možemo u ovom času dohvatiti ispis. Molimo pokušajte kasnije.');
    }

    if(result && result.data) {
      this.snackbarService.open('PDF je preuzet. Provjerite preuzimanja.');
    }
  }

  public openModal() {
    let selected = this.runners.map(({id}) => id);
    let data = [];
    data['selected'] = selected;
    data['captainId'] = this.state.captainId;
    data['reservationId'] = this.state.id;
    data['raceId'] = this.state.raceId;

    this.dialogService.openRunnerSelect(data).subscribe(result => {
      if(result == undefined) {
        return;
      }

      if(result) {
        this.snackbarService.open('Uspješno ste dodali trkače na rezervaciju.');
      }

      if(!result) {
        this.snackbarService.open('Trkači nisu dodani. Probajte ponoviti unos nakon što osvježite stranicu.');
      }

      this.loadData(this.state.raceId);
    });
  }

  public async deleteReservation($event) {
    let result: IResult;

    this.dialogService.openConfirmationDialog('reservation-runner-delete').subscribe(async (res: any ) => {
      if(!res || !res.result)
      {
        return;
      }

      result = await this.reservationsService.deleteRunnerFromReservation($event.id, this.state.id);

      if(result && result.errors.length > 0) {
        this.snackbarService.open('Dogodila se pogreška! Ako se ponovi, kontaktirajte sistem administratora.');
        return;
      }

      if(result && result.data) {
        this.snackbarService.open('Uspješno ste uklonili trkača s rezervacije.');
        this.loadData(this.state.raceId);
        return;
      }
    });


  }
}
