import { RacesService } from './../../core/Services/Data/races.service';
import { ModalService } from './../../core/Services/modal.service';
import { SnackbarService } from './../../core/Services/snackbar.service';
import { IResult } from './../../core/Models/IResult';
import { AuthenticationService } from './../../core/Services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ReservationsService } from '@core/Services/Data/reservations.service';
import { Subject } from 'rxjs';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent implements OnInit {
  selectedRace = new Subject<string>();
  selectedRunners: any;
  public setColumns: string[] = [
    'id',
    'reservationDate',
    'raceName',
    'admissions',
    'paymentStatus',
    'invoice',
    'billing',
    'options'
  ];

  constructor(private reservationsService: ReservationsService, private router: Router, private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService, private snackbarService: SnackbarService, private dialog: ModalService, private raceService: RacesService) { }

  ngOnInit() {
    if(this.auth.getRole() == "organizer") {
      this.setColumns.splice(3, 0, 'teamName');
    }
    if(this.auth.getRole() == "admin") {
      this.setColumns.splice(2, 0, 'organizerName');
      this.setColumns.splice(4, 0, 'teamName');
    }
    this.loadData();
  }

  public refresh() {
    this.loadData();
  }

  private async loadData(race = null) {
    let response;

    if (race === null) {
      response = await this.reservationsService.getAllReservations();
    } else {
      response = await this.reservationsService.getAllReservationsByRaceId(race.id);
    }

    if (response && response.errors.length > 0) {
      this.snackbarService.open('Dogodila se pogreška! Ako se ponovi, kontaktirajte sistem administratora.');
      return;
    }

    for(let item of response.data) {
      if(item.raceId) {
        item['raceName'] = item.race.name;
      }
    }

    if (response && response.data) {
      this.selectedRunners = response.data;
      return;
    }

  }

  selectRace($event) {
    this.loadData($event);
    this.selectedRace.next($event);
  }

  ngOnDestroy() {
    this.selectedRace.complete();
  }

  editReservation($event) {
    const navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, state: $event };
    this.router.navigate(['details'], navigationExtras);
  }

  public async deleteReservation($event) {
    let result: IResult;

    this.dialog.openConfirmationDialog('reservation-delete').subscribe(async (res: any) => {
      if (!res) {
        return;
      }

      result = await this.reservationsService.deleteReservation($event.id);

      if (result && result.errors.length > 0) {
        this.snackbarService.open('Dogodila se pogreška! Ako se ponovi, kontaktirajte sistem administratora.');
        return;
      }

      if (result && result.data) {
        this.snackbarService.open('Uspješno ste obrisali rezervaciju.');
        this.loadData();
        return;
      }
    });
  }

  public paymentChange($event) {
    this.loadData();
  }
}
