import { SnackbarService } from '@core/Services/snackbar.service';
import { IResult } from '@core/Models/IResult';
import { AuthenticationService } from './../authentication.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { UtilityService } from '../utility.service';
import { first } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class OrganizersService {
  private logo;

  constructor(private api: ApiService, private util: UtilityService, private auth: AuthenticationService, private snackbarService: SnackbarService) {
    this.init();
  }

  init() {
    this.auth.isAuthorized();
  }

  public async getAll(): Promise<IResult> {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.getAllOrganizers().pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    result.data = this.util.keysToCamelCase(result.data);
    return result;
  }

  public async getById(organizerId): Promise<IResult> {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.getOrganizerById(organizerId).pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    result.data = this.util.keysToCamelCase(result.data);
    return result;
  }

  public async editOrganizer(organizer) {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.editOrganizer(organizer).pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    return result;
  }

  public async deleteOrganizer(organizer) {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.deleteOrganizer(organizer).pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    return result;
  }

  public async addNewOrganizer(organizer) {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.addNewOrganizer(organizer).pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    return result;
  }

  public async getCurrentOrganizer() {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.getCurrentOrganizer().pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    result.data = this.util.keysToCamelCase(result.data);
    return result;
  }

  public async encodeAndUploadLogo(logo) {
    let content;
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      content = await this.util.encodeImage(logo);
    } catch(err) {
      this.snackbarService.open('Nešto je pošlo po krivu. Probajte učitati stranicu ponovno!');
    }

    try {
      result.data = await this.api.uploadOrganizerLogo(content).pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    return result;
  }

  public async getLogo() {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.getOrganizerLogo().pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    return result;
  }

  public async getOrganizerContact() {
    let result: IResult = {
      data: {},
      errors: []
    }

    try {
      result.data = await this.api.getOrganizerContact().pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    return result;
  }
}
