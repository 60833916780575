import { SnackbarService } from './../../../core/Services/snackbar.service';
import { LookupsService } from '@core/Services/Data/lookups.service';
import { InventoryService } from './../../../core/Services/Data/inventory.service';
import { Component, Input, SimpleChanges, SimpleChange, AfterViewInit } from '@angular/core';
import { IInventory, IIntervals } from '@core/Models/IInventory';
import { UtilityService } from '@core/Services/utility.service';
import { CurrencyPipe } from '@angular/common';
import { IResult } from '@core/Models/IResult';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  providers: [CurrencyPipe]
})
export class PaymentsComponent implements AfterViewInit {
  @Input() amount: any;
  @Input() raceId: any;
  @Input() organizerCurrency: string;
  inventory: IInventory;
  intervals: IIntervals;
  total: any;
  currency: any;
  price: any;

  constructor(private inventoryService: InventoryService, private currencyPipe: CurrencyPipe,
    private lookupService: LookupsService, private snackbarService: SnackbarService, private util: UtilityService) { }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    const amount: SimpleChange = changes.amount;
    const raceId: SimpleChange = changes.raceId;
    if (raceId && raceId.currentValue) {
      if (amount && amount.currentValue) {
        this.loadData(raceId.currentValue, amount);
      }
    }
  }

  private async loadData(raceId = null, amount = null)  {
    let result: any;
    if (raceId == null || amount == null) {
      return;
    }

    result = await this.inventoryService.getIntervals(raceId);

    if (result.errors.length > 0) {
      this.snackbarService.open('Neuspešno dohvaćanje podataka. Probajte ponovno učitati stranicu!');
    } else {

      result.data.intervals = this.util.keysToCamelCase(result.data.intervals);

      this.inventory = {
        id: result.data.id,
        name: result.data.name,
        description: result.data.description,
        raceId: result.data.raceId,
        type: result.data.type,
        order: result.data.order,
        createdAt: result.data.createdAt,
        updatedAt: result.data.createdAt,
        intervals: []
      };

      this.intervals = result.data.intervals;

      this.currency = this.organizerCurrency;

      if(this.inventory && this.intervals) {
        this.total = amount.currentValue * this.intervals.price;
        this.total = this.currencyPipe.transform(this.total, this.currency, true, '1.2-2');
        this.price = this.currencyPipe.transform(this.intervals.price, this.currency, true, '1.2-2');
      } else {
        this.total = 0;
        this.price = 0;
      }
    }
  }

}
