import { DialogComponent } from './../dialog.component';
import { ICaptain } from './../../../core/Models/ICaptain';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  public dialogContent;
  public dialogTitle;
  public dialogIcon;

  constructor(private dialogRef: MatDialogRef<DialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: string) {
    this.getMessage(dialogData);
  }
  ngOnInit() {
  }

  getMessage(reason) {
    switch (reason) {
      case 'reservation-delete': {
        this.dialogTitle = 'Brisanje rezervacije';
        this.dialogIcon = 'warning';
        this.dialogContent = 'Jeste li sigurni da želite obrisati rezervaciju?';
        break;
      }
      case 'reservation-runner-delete': {
        this.dialogTitle = 'Brisanje trkača s rezervacije';
        this.dialogIcon = 'warning';
        this.dialogContent = 'Jeste li sigurni da želite obrisati trkača s rezervacije?';
        break;
      }
      case 'payment-change': {
        this.dialogTitle = 'Promjena statusa plaćanja';
        this.dialogIcon = 'info';
        this.dialogContent = 'Jeste li sigurni da želite promijeniti status plaćanja?';
        break;
      }
      case 'runner-delete': {
        this.dialogTitle = 'Brisanje trkača';
        this.dialogIcon = 'warning';
        this.dialogContent = 'Jeste li sigurni da želite obrisati trkača?';
        break;
      }
      case 'team-delete': {
        this.dialogTitle = 'Brisanje tima';
        this.dialogIcon = 'warning';
        this.dialogContent = 'Jeste li sigurni da želite obrisati tim?';
        break;
      }
      case 'race-lock': {
        this.dialogTitle = 'Zaključavanje utrke';
        this.dialogIcon = 'warning';
        this.dialogContent = 'Jeste li sigurni da želite zaključati utrku?'
        break;
      }
      case 'organizer-delete': {
        this.dialogTitle = 'Brisanje organizatora';
        this.dialogIcon = 'warning';
        this.dialogContent = 'Jeste li sigurni da želite obrisati organizatora?'
        break;
      }
      default: {
        this.dialogContent = 'Jeste li sigurni da želite potvrditi akciju?';
        break;
      }
    }
  }

  onSubmit() {
    this.dialogRef.close({ result: true });
  }

  onClose(event) {
    event.preventDefault();
    this.dialogRef.close({ result: false });
  }
}
