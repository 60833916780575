import { ModalService } from '@core/Services/modal.service';
import { SnackbarService } from './../../core/Services/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { OrganizersService } from '@core/Services/Data/organizers.service';

@Component({
  selector: 'app-organizers',
  templateUrl: './organizers.component.html',
  styleUrls: ['./organizers.component.scss']
})
export class OrganizersComponent implements OnInit {
  selectedOrganizers;
  public setColumns: string[] = [
    'position',
    'legalName',
    'pin',
    'subdomain',
    'email',
    'country',
    'options'
  ];

  constructor(private organizerService: OrganizersService, private snackbarService: SnackbarService, private dialog: ModalService) { }

  ngOnInit() {
    this.loadData();
  }

  public async refresh() {
    await this.loadData();
  }

  private async loadData() {
    let result = await this.organizerService.getAll();

    if(result && result.errors.length > 0) {
      this.snackbarService.open('Dogodila se pogreška. Molimo ponovno učitajte stranicu!');
    }

    if(result && result.data) {
      this.selectedOrganizers = result.data;
    }
  }

  editOrganizer(event) {
    this.dialog.openOrganizerAdd(event).subscribe(res => {
      if(!res || res == undefined) {
        return;
      }
      this.loadData();
    });
  }

  public async deleteOrganizer(event) {
    this.dialog.openConfirmationDialog('organizer-delete').subscribe(async res => {
      if(!res || res == undefined) {
        return;
      }

      let result = await this.organizerService.deleteOrganizer(event);

      if(result && result.errors.length > 0) {
        this.snackbarService.open('Nešto je pošlo po krivu. Molimo ponovno učitajte stranicu!');
        return;
      } else {
        this.snackbarService.open('Uspješno ste izbrisali organizatora: ' + event.legalName);
        this.loadData();
      }
    });
  }
}
