import { UtilityService } from '@core/Services/utility.service';
import { SnackbarService } from './../../core/Services/snackbar.service';
import { IResult } from '@core/Models/IResult';
import { ModalService } from './../../core/Services/modal.service';
import { AuthenticationService } from '@core/Services/authentication.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { RunnersService } from '@core/Services/Data/runners.service';
import { MatSnackBar, MatTableDataSource, MatPaginator, MatSort, MatSortModule, MatTableModule, MatSortable } from '@angular/material';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-runners',
  templateUrl: './runners.component.html',
  styleUrls: ['./runners.component.scss']
})
export class RunnersComponent implements OnInit {
  selectedRace = new Subject<string>();
  selectedRunners: any;
  public setColumns: string[] = [
    'position',
    'name',
    'lastName',
    'sex',
    'phone',
    'shirtSize',
    'options'
  ];

  constructor(private runnerService: RunnersService, private dialog: ModalService, private snackbarService: SnackbarService,
    private util: UtilityService, private auth: AuthenticationService
  ) { }

  ngOnInit() {
    if (this.auth.getRole() == 'organizer') {
      this.setColumns.splice(1, 0, 'teamName');

    }
    if(this.auth.getRole() == "admin") {
      this.setColumns.splice(1, 0, 'organizerName');
      this.setColumns.splice(2, 0, 'teamName');
    }

    this.loadData();
  }

  public refresh() {
    this.loadData();
  }

  private async loadData(race = null) {
    let response;

    if (!race) {
      response = await this.runnerService.getAllRunners();
    } else {
      response = await this.runnerService.getAllRunnersByRaceId(race.id);
    }

    if(response && response.errors.length > 0)
    {
      this.snackbarService.open("Pogreška pri dohvaćanju takmičara. Probajte ponovno učitati stranicu!");
    }

    if (response && this.util.isEmptyObject(response.data)) {
      this.selectedRunners = [];
    }

    if (response && response.data && !this.util.isEmptyObject(response.data)) {
      this.selectedRunners = response.data;
    }
  }

  selectRace($event) {
    this.loadData($event);
    this.selectedRace.next($event);
  }

  ngOnDestroy() {
    this.selectedRace.complete();
  }

  edit($event) {
    this.dialog.openRunnerAdd($event).subscribe(res => {
      if(!res || res == undefined) {
        return;
      }
      this.loadData();
    });
  }

  delete($event) {
    let result: IResult;

    this.dialog.openConfirmationDialog('runner-delete').subscribe(async (res: any) => {
      if (!res.result) {
        return;
      }

      result = await this.runnerService.deleteRunner($event.id);

      if (result && result.errors.length > 0) {
        this.snackbarService.open('Dogodila se pogreška! Ako se ponovi, kontaktirajte sistem administratora.');
        return;
      }

      if (result && result.data) {
        this.snackbarService.open('Uspješno ste obrisali takmičara.');
        this.loadData();
        return;
      }
    });
  }
}
