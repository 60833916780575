import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { UtilityService } from '../utility.service';
import { AuthenticationService } from '../authentication.service';
import { first } from 'rxjs/operators';
import { IResult } from '@core/Models/IResult';


@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  constructor(private api: ApiService, private util: UtilityService, private auth: AuthenticationService) {
    this.init();
  }

  init() {
    this.auth.isAuthorized();
  }

  public async getAllReservations() {
    let reservation: IResult = {
      data: {},
      errors: []
    };

    try {
      reservation.data = await this.api.getAllReservations().pipe(first()).toPromise();
    } catch (e) {
      reservation.errors = e.error;
    }

    reservation.data = this.util.keysToCamelCase(reservation.data);
    return reservation;
  }

  public async getAllReservationsByRaceId(raceId: any) {
    let reservation: IResult = {
      data: {},
      errors: []
    };

    try {
      reservation.data = await this.api.getAllReservationsByRaceId(raceId).pipe(first()).toPromise();
    } catch (e) {
      reservation.errors = e.error;
    }

    reservation.data = this.util.keysToCamelCase(reservation.data);
    return reservation;
  }

  public async addNewReservation(data) {
    let reservations: IResult = {
      data: {},
      errors: []
    };

    try {
      reservations.data = await this.api.addNewReservation(data).pipe(first()).toPromise();
    } catch (e) {
      reservations.errors = e.error;
    }

    return reservations;
  }

  public async editReservation(data): Promise<IResult> {
    let reservation: IResult = {
      data: {},
      errors: []
    };

    try {
      reservation.data = await this.api.addNewReservation(data).pipe(first()).toPromise();
    } catch (e) {
      reservation.errors = e.error;
    }

    return reservation;
  }

  public async getById(data): Promise<IResult> {
    let reservation: IResult = {
      data: {},
      errors: []
    };

    try {
      reservation.data = await this.api.getReservationById(data).pipe(first()).toPromise();
    } catch (e) {
      reservation.errors = e.error;
    }

    return reservation;
  }

  public async deleteReservation(data): Promise<IResult> {
    let reservation: IResult = {
      data: {},
      errors: []
    };

    try {
      reservation.data = await this.api.deleteReservation(data).pipe(first()).toPromise();
    } catch (e) {
      reservation.errors = e.error;
    }

    return reservation;
  }

  public async deleteRunnerFromReservation(runnerId, reservationId): Promise<IResult> {
    let deleted: IResult = {
      data: {},
      errors: []
    }

    try {
      deleted.data = await this.api.deleteRunnerFromReservation(runnerId, reservationId).pipe(first()).toPromise();
    } catch (e) {
      deleted.errors = e.error;
    }

    return deleted;

  }

  public async setPaymentStatus(reservationId): Promise<IResult> {
    let deleted: IResult = {
      data: {},
      errors: []
    }

    try {
      deleted.data = await this.api.setPaymentStatus(reservationId).pipe(first()).toPromise();
    } catch (e) {
      deleted.errors = e.error;
    }

    return deleted;

  }

  public async getTotalRunners(reservationId) {
    let total: IResult = {
      data: {},
      errors: []
    }

    try {
      total.data = await this.api.getTotalRunners(reservationId).pipe(first()).toPromise();
    } catch (e) {
      total.errors = [e.error];
    }

    return total;
  }
}
