import { FormErrorStateMatcher } from './../../registration/registration.component';
import { IResult } from './../../../core/Models/IResult';
import { SnackbarService } from '@core/Services/snackbar.service';
import { SettingsService } from './../../../core/Services/Data/settings.service';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { ErrorStateMatcher } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';



@Component({
  selector: 'app-document-editor',
  templateUrl: './document-editor.component.html',
  styleUrls: ['./document-editor.component.scss']
})
export class DocumentEditorComponent implements OnInit {
  public Editor = DocumentEditor;
  public templateAdd: FormGroup;
  public matcher;
  public isDisabled;
  public template;
  @Input() type: string;

  public model = {
    editorData: '<p>Hello World!</p>'
  }

  constructor(private settingsService: SettingsService, private snackbarService: SnackbarService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.validation();
    this.loadData();
  }


  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  public async onSave(data) {
    let result: IResult;

    data = this.templateAdd.getRawValue();

    result = await this.settingsService.saveTemplate(this.model.editorData, data);

    if(result && result.errors.length > 0) {
      this.snackbarService.open('Dogodila se pogreška! Ako se ponovi, kontaktirajte sistem administratora.');
      return;
    }

    if(result && result.data) {
      this.snackbarService.open('Uspješno ste spremili predložak.');
      return;
    }
  }

  private validation() {
    this.templateAdd = this.formBuilder.group({
      title: ['', Validators.required],
      templateType: ['', Validators.required],
    });

    this.matcher = new FormErrorStateMatcher();
  }

  get form() { return this.templateAdd.controls; }

  private async loadData() {
    this.form.templateType.setValue(this.type);
    this.templateAdd.controls['templateType'].disable();

    const result = await this.settingsService.getTemplate(this.type);

    if(result && result.errors.length > 0) {
      this.snackbarService.open('Dogodila se pogreška! Ako se ponovi, kontaktirajte sistem administratora.');
      return;
    }

    if(result && result.data) {
      this.template = result.data as any;
      this.form.title.setValue(this.template.templateName);
      this.model.editorData = this.template.templateContent;
      return;
    }
  }
}
