import { UtilityService } from './../../../core/Services/utility.service';
import { CaptainsService } from './../../../core/Services/Data/captains.service';
import { SnackbarService } from './../../../core/Services/snackbar.service';
import { DialogComponent } from './../dialog.component';
import { Component, OnInit, Optional, Inject, Input, SimpleChange } from '@angular/core';
import { ErrorStateMatcher, MatDialogRef, MAT_DIALOG_DATA, throwToolbarMixedModesError } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ICaptain } from '@core/Models/ICaptain';
import { IResult } from '@core/Models/IResult';
import { FormErrorStateMatcher } from '@components/shared/form-error-state-matcher/form-error-state-matcher';
import { LookupsService } from '@core/Services/Data/lookups.service';
import { AuthenticationService } from '@core/Services/authentication.service';



@Component({
  selector: 'app-captain-add',
  templateUrl: './captain-add.component.html',
  styleUrls: ['./captain-add.component.scss']
})
export class CaptainAddComponent implements OnInit {
  submitted = false;
  billing;
  matcher;
  role;
  edit = false;
  public captainAdd: FormGroup;
  public organizer = {
    all: [],
    list: [],
    filtered: []
  }
  @Input() dataToEdit: ICaptain;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DialogComponent>,
    private snackBarService: SnackbarService,
    private captainService: CaptainsService,
    private util: UtilityService,
    private lookupService: LookupsService,
    private snackbarService: SnackbarService,
    private auth: AuthenticationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: ICaptain) {
    this.dataToEdit = dialogData;
  }

  ngOnInit() {
    this.role = this.auth.getRole();

    this.validation();
    this.billing = false;

    if (this.dataToEdit) {
      this.populate(this.dataToEdit);
    }
    this.loadData();
  }
  async loadData() {
    let lookupString = 'organizer';
    const lookups: any = await this.lookupService.getMultipleLookup(lookupString);
    if(lookups && this.util.isEmptyObject(lookups.data)) {
      this.snackbarService.open('Nešto je pošlo po krivu. Molimo ponovno učitajte stranicu!');
      return false;
    }
    if(lookups) {
      this.setDropdowns(lookups);
    }
  }
  private setDropdowns(lookups) {
    this.organizer.all = lookups.data.organizer;
    this.organizer.list = lookups.data.organizer;
    this.organizer.filtered = lookups.data.organizer;
    this.captainAdd.patchValue({
      organizer: this.organizer
    })
  }
  private populate(data: ICaptain) {
    this.form.name.setValue(data.name);
    this.form.lastName.setValue(data.lastName);
    this.form.email.setValue(data.email);
    this.form.companyName.setValue(data.companyName);
    this.form.pin.setValue(data.pin);
    this.form.city.setValue(data.city);
    this.form.teamName.setValue(data.teamName);
    this.form.phone.setValue(data.phone);
    this.form.address.setValue(data.address);
    this.form.postcode.setValue(data.postcode);
    this.form.totalEmployees.setValue(data.totalEmployees);
    this.form.business.setValue(data.business);
    this.form.customWelcome.setValue(data.customWelcome);

    if (data.billingAddress || data.billingCity || data.billingCompany || data.billingPin || data.billingPhone) {
      this.billing = true;
      this.form.billingCompany.setValue(data.billingCompany);
      this.form.billingCity.setValue(data.billingCity);
      this.form.billingAddress.setValue(data.billingAddress);
      this.form.billingPostcode.setValue(data.billingPostcode);
      this.form.billingPin.setValue(data.billingPin);
      this.form.billingPhone.setValue(data.billingPhone);
    }

    this.edit = true;
    this.form.email.disable();
  }

  ngOnChanges(changes: SimpleChange) {
    let change: any = changes;
    if(change && change.dataToEdit.currentValue)
    {
      this.dataToEdit = change.dataToEdit.currentValue;
      this.populate(this.dataToEdit);
    }
  }

  private validation() {
    this.captainAdd = this.formBuilder.group({
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      companyName: ['', Validators.required],
      pin: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
      teamName: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      totalEmployees: ['', Validators.required],
      business: ['', Validators.required],
      customWelcome: ['', Validators.nullValidator],
      billingCompany: ['', Validators.nullValidator],
      billingCity: ['', Validators.nullValidator],
      billingAddress: ['', Validators.nullValidator],
      billingPostcode: ['', Validators.nullValidator],
      billingPhone: ['', Validators.nullValidator],
      billingPin: ['', Validators.nullValidator],
      organizer: ['', Validators.required]
    });

    this.matcher = new FormErrorStateMatcher();
  }

  get form() { return this.captainAdd.controls; }

  async onClickSubmit(data: any) {
    let result: IResult;

    this.submitted = true;
    for (const control in this.form) {
      if (this.form[control].invalid) {
        return;
      }
    }

    if(this.billing == false) {
      data.billingAddress = '';
      data.billingCity = '';
      data.billingCompany = '';
      data.billingPhone = '';
      data.billingPin = '';
      data.billingPostcode = '';
    }

    if (this.edit) {
      data.id = this.dataToEdit.id;
      result = await this.captainService.editCaptain(data);
    } else {
      result = await this.captainService.addNewCaptain(data);
    }

    if (result && result.errors.length > 0) {
      this.snackBarService.open("Došlo je do pogreške. Provjerite svoje podatke ili ponovno učitajte stranicu!");
      this.submitted = false;
      return;
    }

    if (result && !this.edit) {
      this.snackBarService.open("Uspješno ste dodali kapitana - " + data.name + " " + data.lastName);
      if(!this.util.isEmptyObject(this.dialogRef)) {
        this.dialogRef.close({ result: true });
      }
    }

    if (this.edit) {
      this.snackBarService.open("Uspešno ste izmenili kapitana - " + data.name + " " + data.lastName);
      if(!this.util.isEmptyObject(this.dialogRef)) {
        this.dialogRef.close({ result: true });
      }
    }
  }
}
