import { SnackbarService } from './../../../core/Services/snackbar.service';
import { UtilityService } from '@core/Services/utility.service';
import { RunnersService } from '@core/Services/Data/runners.service';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogComponent } from '../dialog.component';

@Component({
  selector: 'app-runners-select',
  templateUrl: './runners-select.component.html',
  styleUrls: ['./runners-select.component.scss']
})
export class RunnersSelectComponent implements OnInit {
  private reservationId;
  public runners;
  private selectedRunners = [];
  public setColumns: string[] = [
    'checkbox',
    'name',
    'lastName',
    'pin',
  ];

  constructor(private runnerService: RunnersService,
    private dialogRef: MatDialogRef<DialogComponent>,
    private util: UtilityService,
    private snackbarService: SnackbarService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any) {
    this.loadData(dialogData);
    this.reservationId = dialogData.reservationId;
  }

  ngOnInit() {
  }

  private async loadData(data) {
    let result: any = await this.runnerService.getAvailableRunnersByCaptainId(data.captainId, data.raceId);

    if(result && result.errors.length > 0) {
      this.snackbarService.open("Nešto je pošlo po krivu. Probajte ponovno učitati stranicu!");
    }

    if(result && this.util.isEmptyObject(result.data)) {
      this.snackbarService.open("Nema trkača koje možete dodati!");
      this.selectedRunners = [];
    }

    this.runners = result.data;
  }

  public getSelectedRunners($event) {
    this.selectedRunners = $event;
  }

  public async onSubmit() {
    let result = this.runnerService.addRunnersToReservation(this.selectedRunners, this.reservationId);

    if(result) {
      this.dialogRef.close({ result: true });
    }

    this.dialogRef.close({ result: false });
  }
}
