import { IAdminLoginRequest } from './../Models/Requests/IAdminLoginRequest';
import { UtilityService } from './utility.service';
import { ApiService } from './api.service';
import { IUser } from '@core/Models/IUser';
import { Injectable } from '@angular/core';
import { ILoginRequest } from '@core/Models/Requests/ILoginRequest';
import { ILoginResponse } from '@core/Models/Response/ILoginResponse';
import { first } from 'rxjs/operators';
import { RedirectService } from './redirect.service';
import { IResult } from '@core/Models/IResult';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUser: IUser | null = {
    id: null,
    username: null,
    firstName: null,
    lastName: null,
    pin: null,
    role: null,
    token: null
  };

  constructor(private api: ApiService, private util: UtilityService, private redirect: RedirectService) { }

  getCurrentUser(): IUser {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return this.currentUser;
  }

  setCurrentUser(user: IUser, token) {
    if (user && token) {
      this.currentUser = user;
      this.currentUser.token = token ? token : null;
      localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    }
  }

  getToken() {
    this.getCurrentUser();

    if (this.currentUser.token) {
      return this.currentUser.token;
    }

    return false;
  }

  getRole() {
    this.getCurrentUser();
    return this.currentUser.role;
  }

  isAuthorized() {
    this.getCurrentUser();
    if (this.util.isEmptyObject(this.currentUser, true)) {
      this.redirect.login();
    }

    return true;
  }

  async login(inputEmail: string, inputPassword: string, inputRememberMe: boolean) {
    let result: IResult = {
      data: {},
      errors: []
    };

    const data: ILoginRequest = {
      email: inputEmail,
      password: inputPassword,
      remember_me: inputRememberMe,
      subdomain: this.util.getSubdomain()
    };

    try {
      result.data = await this.api.login(data).pipe(first()).toPromise() as ILoginResponse;
      if (result && result.data && !this.util.isEmptyObject(result.data)) {
        let data: any = result.data;
        this.setCurrentUser(data.user, data.access_token);
      }
    } catch (e) {
      result.errors = [e.error];
    }

    return result;
  }

  async loginAdmin(inputEmail: string, inputPassword: string, inputRememberMe: boolean) {
    let result: IResult = {
      data: {},
      errors: []
    };

    const data: IAdminLoginRequest = {
      email: inputEmail,
      password: inputPassword,
      remember_me: inputRememberMe,
    };

    try {
      result.data = await this.api.loginAdmin(data).pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    if (result && result.data && !this.util.isEmptyObject(result.data)) {
      let data: any = result.data;
      this.setCurrentUser(data.user, data.access_token);
    }

    return result;
  }

  async logout() {
    this.getCurrentUser();
    localStorage.setItem('currentUser', null);
    const result = await this.api.logout(this.currentUser);
    this.currentUser = null;
    this.redirect.login();
  }

  async registerUser(data) {
    let result: IResult = {
      data: {},
      errors: []
    };

    data['subdomain'] = this.util.getSubdomain();

    try {
      result.data = await this.api.register(data).pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    return result;
  }


  loggedIn(): boolean {
    if (!this.util.isEmptyObject(this.currentUser, true)) {
      return true;
    } else if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      return this.currentUser ? true : false;
    } else {
      return false;
    }
  }

}
