import { IRunner } from './../../Models/IRunner';
import { CaptainMapper } from './../../Models/Mappers/CaptainMapper';
import { ICaptain } from '@core/Models/ICaptain';
import { CaptainsService } from '@core/Services/Data/captains.service';
import { IResult } from '@core/Models/IResult';
import { AuthenticationService } from '@core/Services/authentication.service';
import { Injectable, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { UtilityService } from '../utility.service';
import { first } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RunnersService {


  constructor(private api: ApiService, private util: UtilityService, private auth: AuthenticationService, private captainService: CaptainsService, private captainMapper: CaptainMapper) {
    this.init();
  }

  init() {
    this.auth.isAuthorized();
  }

  async getAllRunners() {
    let runners: IResult = {
      data: {},
      errors: []
    };

    try {
      runners.data = await this.api.getAllRunners().pipe(first()).toPromise();
    } catch (e) {
      runners.errors = [e.error];
    }

    runners.data = this.util.keysToCamelCase(runners.data);
    return runners;
  }

  async getAllRunnersByRaceId(raceId) {
    let runners: IResult = {
      data: {},
      errors: []
    };

    try {
      runners.data = await this.api.getAllRunnersByRaceId(raceId).pipe(first()).toPromise();
    } catch (e) {
      runners.errors = [e.error];
    }

    runners.data = this.util.keysToCamelCase(runners.data);
    return runners;
  }

  async getAvailableRunnersByCaptainId(captainId, raceId) {
    let runners: IResult = {
      data: {},
      errors: []
    };

    try {
      runners.data = await this.api.getAvailableRunnersByCaptainId(captainId, raceId).pipe(first()).toPromise();
    } catch (e) {
      runners.errors = [e.error];
    }

    runners.data = this.util.keysToCamelCase(runners.data);
    return runners;
  }

  async addNewRunner(runner) {
    let runners: IResult = {
      data: {},
      errors: []
    };

    if (!runner.hasOwnProperty('captain')) {
      let captain: any = await this.captainService.getCurrentCaptain();
      runner['captain'] = this.captainMapper.fromArray(captain.data);
    }

    try {
      runners.data = await this.api.addNewRunner(runner).pipe(first()).toPromise();
    } catch (e) {
      runners.errors = [e.error];
    }

    runners.data = this.util.keysToCamelCase(runners.data);
    return runners;
  }

  async editRunner(value: any) {
    let runner: IResult = {
      data: {},
      errors: []
    };

    if (!value.hasOwnProperty('captain')) {
      value['captain'] = this.auth.getCurrentUser();
    }

    try {
      runner.data = await this.api.editRunner(value).pipe(first()).toPromise();
    } catch (e) {
      runner.errors = [e.error];
    }

    return runner;
  }

  async registerNewRunner(data, reservationId) {
    let runner: IResult = {
      data: {},
      errors: []
    };

    data['reservationId'] = reservationId;

    try {
      runner.data = await this.api.registerRunner(data).pipe(first()).toPromise();
    } catch (e) {
      runner.errors = [e.error];
    }

    return runner;
  }

  async addRunnersToReservation(selectedRunners, reservationId) {
    let runner: IResult = {
      data: {},
      errors: []
    };

    let data = [];
    data['selectedRunners'] = selectedRunners;
    data['reservationId'] = reservationId;

    try {
      runner.data = await this.api.addRunnersToReservation(data).pipe(first()).toPromise();
    } catch (e) {
      runner.errors = [e.error];
    }

    return runner;
  }

  async getRunnersByReservation(reservationId) {
    let runner: IResult = {
      data: {},
      errors: []
    };

    try {
      runner.data = await this.api.getAllRunnersByReservationId(reservationId).pipe(first()).toPromise();
    } catch (e) {
      runner.errors = [e.error];
    }

    runner.data = this.util.keysToCamelCase(runner.data);

    return runner;
  }

  async deleteRunner(runnerId) {
    let runner: IResult = {
      data: {},
      errors: []
    };

    try {
      runner.data = await this.api.deleteRunner(runnerId).pipe(first()).toPromise();
    } catch (e) {
      runner.errors = [e.error];
    }

    runner.data = this.util.keysToCamelCase(runner.data);

    return runner;
  }
}
