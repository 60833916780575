import { AdminLoginComponent } from './components/login/admin-login/admin-login.component';
import { RegistrationRunnerComponent } from './components/registration/registration-runner/registration-runner.component';
import { SettingsAdminComponent } from './components/settings/settings-admin/settings-admin.component';
import { HomeOrganizerComponent } from './components/home/home-organizer/home-organizer.component';
import { HomeAdminComponent } from './components/home/home-admin/home-admin.component';
import { HomeCaptainComponent } from './components/home/home-captain/home-captain.component';
import { DashboardAdminComponent } from '@components/dashboard/dashboard-admin/dashboard-admin.component';
import { CaptainAuthGuard } from '@core/Guards/captainAuth.guard';
import { DashboardCaptainComponent } from '@components/dashboard/dashboard-captain/dashboard-captain.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '@components/login/login.component';
import { RegistrationComponent } from '@components/registration/registration.component';
import { AdminAuthGuard } from '@core/Guards/adminAuth.guard';
import { DashboardOrganizerComponent } from '@components/dashboard/dashboard-organizer/dashboard-organizer.component';
import { OrganizerAuthGuard } from '@core/Guards/organizerAuth.guard';
import { RunnersComponent } from '@components/runners/runners.component';
import { RacesComponent } from '@components/races/races.component';
import { TeamsComponent } from '@components/teams/teams.component';
import { ReservationsComponent } from '@components/reservations/reservations.component';
import { ReservationDetailComponent } from '@components/reservations/reservation-detail/reservation-detail.component';
import { SettingsCaptainComponent } from '@components/settings/settings-captain/settings-captain.component';
import { SettingsOrganizerComponent } from '@components/settings/settings-organizer/settings-organizer.component';
import { EntryListComponent } from '@components/entry-list/entry-list.component';
import { OrganizersComponent } from '@components/organizers/organizers.component';

const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'login', component: LoginComponent },
  { path: 'admin', component: AdminLoginComponent},
  { path: 'registration', component: RegistrationComponent },
  { path: 'microsite/:hash', component: RegistrationRunnerComponent},

  // CAPTAIN ROUTES
  {
    path: 'captain',
    component: HomeCaptainComponent,
    canActivate: [CaptainAuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'prefix'},
      { path: 'dashboard', component: DashboardCaptainComponent, canActivate: [CaptainAuthGuard]},
      { path: 'runners', component: RunnersComponent, canActivate: [CaptainAuthGuard]},
      { path: 'races', component: RacesComponent, canActivate: [CaptainAuthGuard]},
      { path: 'reservations', component: ReservationsComponent, canActivate: [CaptainAuthGuard]},
      { path: 'reservations/details', component: ReservationDetailComponent, canActivate: [CaptainAuthGuard]},
      { path: 'settings', component: SettingsCaptainComponent, canActivate: [CaptainAuthGuard]}
    ]
  },

  // ADMIN ROUTES
  {
    path: 'admin',
    component: HomeAdminComponent,
    canActivate: [AdminAuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'prefix'},
      { path: 'dashboard', component: DashboardAdminComponent, canActivate: [AdminAuthGuard]},
      { path: 'organizers', component: OrganizersComponent, canActivate:  [AdminAuthGuard]},
      { path: 'races', component: RacesComponent, canActivate: [AdminAuthGuard]},
      { path: 'teams', component: TeamsComponent, canActivate: [AdminAuthGuard]},
      { path: 'runners', component: RunnersComponent, canActivate: [AdminAuthGuard]},
      { path: 'reservations', component: ReservationsComponent, canActivate: [AdminAuthGuard]},
      { path: 'reservations/details', component: ReservationDetailComponent, canActivate: [AdminAuthGuard]},
      { path: 'settings', component: SettingsAdminComponent, canActivate: [AdminAuthGuard]}

    ]
  },

  // ORGANIZER ROUTES
  {
    path: 'organizer',
    component: HomeOrganizerComponent,
    canActivate: [OrganizerAuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'prefix'},
      { path: 'dashboard', component: DashboardOrganizerComponent, canActivate: [OrganizerAuthGuard]},
      { path: 'races', component: RacesComponent, canActivate: [OrganizerAuthGuard]},
      { path: 'teams', component: TeamsComponent, canActivate: [OrganizerAuthGuard]},
      { path: 'runners', component: RunnersComponent, canActivate: [OrganizerAuthGuard]},
      { path: 'reservations', component: ReservationsComponent, canActivate: [OrganizerAuthGuard]},
      { path: 'reservations/details', component: ReservationDetailComponent, canActivate: [OrganizerAuthGuard]},
      { path: 'settings', component: SettingsOrganizerComponent, canActivate: [OrganizerAuthGuard]},
      { path: 'races/roster', component: EntryListComponent, canActivate: [OrganizerAuthGuard]}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
