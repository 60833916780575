import { AuthenticationService } from '../authentication.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthenticationService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const login = '/login';
    const register = '/register';
    const admin = '/admin';
    let authReq;

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Access-Control-Allow-Origin', '*');

    if (request.url.search(login) !== -1 || request.url.search(register) !== -1 || request.url.search(admin) !== -1) {
      authReq = request.clone({ headers });
      return next.handle(authReq).pipe(
        map((event: HttpEvent<any>) => {
            // if (event instanceof HttpResponse) {
            //     console.log('event--->>>', event);
            // }
            return event;
        }));
    }

    const token = this.auth.getToken();
    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    authReq = request.clone({ headers });

    return next.handle(authReq).pipe(
      map((event: HttpEvent<any>) => {
          // if (event instanceof HttpResponse) {
          //     console.log('event--->>>', event);
          // }
          return event;
      }));
  }
}
