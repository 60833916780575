import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Component, Input, Optional, ElementRef, Injector, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, FormControlName } from '@angular/forms';
import * as _moment from 'moment';
import { MatDatepickerInputEvent, MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY.',
  },
  display: {
    dateInput: 'DD.MM.YYYY.',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'sr' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})
export class DatePickerComponent implements ControlValueAccessor {
  focused;
  ngControl: NgControl;
  control: FormControl;
  private onChange = (value: any) => { };
  private onTouched = (value: any) => { };
  stateChanges = new Subject<void>();
  @Input() dateValue: string = null;
  @Input() errorMessage: string = "This is required.";
  @Input() public placeholder: string = null;
  @Input() public minDate: string = null;
  @Input() public maxDate: string = null;
  @Input() public startDate: string = null;
  @Input() public label: string = null;
  @Output() public change = new EventEmitter();
  @ViewChild('dpInput', { static: false }) public dpInput;


  constructor(@Optional() private controlName: FormControlName, private fm: FocusMonitor, private elRef: ElementRef, private injector: Injector) {
    this.ngControl = this.injector.get(NgControl);
    if(this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit() {
    moment.locale('sr');
    this.control = this.controlName.control;
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    this.dateValue = moment(event.value).format();
    this.stateChanges.next();
    this.change.emit(this.dpInput);
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    if(value !== undefined || value !== '') {
      this.dateValue = moment(value).format();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }


}
