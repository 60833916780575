import { RacesService } from '@core/Services/Data/races.service';
import { Router } from '@angular/router';
import { SnackbarService } from '@core/Services/snackbar.service';
import { UtilityService } from '@core/Services/utility.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrganizersService } from '@core/Services/Data/organizers.service';

@Component({
  selector: 'app-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss']
})
export class LogoUploadComponent implements OnInit {
  logo: any;
  isLoaded: boolean = false;
  public uploading: boolean = false;
  defaultLogo = 'upload.gif';
  @Input() emit: boolean = false;
  @Input() raceId: number;
  @Output() uploadedLogo = new EventEmitter<any>();

  constructor(private organizerService: OrganizersService, private util: UtilityService, private snackbarService: SnackbarService,
    private router: Router, private raceService: RacesService) { }

  ngOnInit() {
    if(this.emit) {
      this.isLoaded = false;
      this.logo = this.defaultLogo;
      if(this.raceId) {
        this.loadData(true);
      }
    } else {
      this.loadData();
    }
  }

  private async loadData(race = false) {
    let result;

    if(race) {
      result = await this.raceService.getRaceLogo(this.raceId);
    } else {
      result = await this.organizerService.getLogo();
    }

    if(result && result.errors.length > 0) {
      this.snackbarService.open('Ne možemo trenutno dohvatiti logo! Probajte ponovno učitati stranicu.');
    }

    if(result && result.data && !this.util.isEmptyObject(result.data)) {
      this.logo = '';
      this.logo = result.data.logo ? result.data.logo : result.data;
      this.isLoaded = true;
    }
  }

  async uploadFile($event) {

    if($event.length == 0) {
      return;
    }

    this.uploading = true;

    if(this.emit)
    {
      try{
        this.readImageAsURL($event[0]);
        let encodedLogo = await this.util.encodeImage($event[0]);
        this.uploadedLogo.emit(encodedLogo);
        this.uploading = false;
        return;
      } catch(e) {
        this.snackbarService.open('Nešto je pošlo po krivu. Probajte učitati stranicu ponovno!');
        return;
      }
    }

    let img: any = await this.organizerService.encodeAndUploadLogo($event[0]);

    if(img) {
      this.snackbarService.open('Logo je uspješno uploadan.');
    }

    this.uploading = false;
    this.ngOnInit();
  }

  getClass() {
    if(this.isLoaded) {
      return 'uploadfilecontainer';
    }

    return 'uploadfilecontainer img-default';
  }

  readImageAsURL(image) {
    let reader = new FileReader();
    reader.onloadend = () => {
      this.logo = reader.result;
    }

    if(image) {
      reader.readAsDataURL(image);
    } else {
      this.snackbarService.open("Pogrešan tip datoteke. Molimo aploudajte sliku (jpg, png, gif...)!");
    }

  }

}

