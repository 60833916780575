import { UtilityService } from '@core/Services/utility.service';
import { AuthenticationService } from './../../core/Services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { SnackbarService } from '@core/Services/snackbar.service';
import { RedirectService } from '@core/Services/redirect.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  billing;
  matcher;
  canRegister;
  public register: FormGroup;

  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private snackBarService: SnackbarService,
    private redirect: RedirectService, private util: UtilityService) {

  }

  ngOnInit() {
    this.validation();
  }

  private validation() {
    this.register = this.formBuilder.group({
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
      passwordConfirmation: ['', Validators.required],
      companyName: ['', Validators.required],
      pin: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
      teamName: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      totalEmployees: ['', Validators.required],
      business: ['', Validators.required],
      billingCompany: ['', Validators.nullValidator],
      billingCity: ['', Validators.nullValidator],
      billingAddress: ['', Validators.nullValidator],
      billingPhone: ['', Validators.nullValidator],
      billingPin: ['', Validators.nullValidator],
    });

    this.matcher = new FormErrorStateMatcher();
  }

  get form() { return this.register.controls; }

  async onClickSubmit(data: any) {

    const result = await this.authenticationService.registerUser(data);

    if (result && result.errors.length > 0) {
      this.snackBarService.open("Greška: " + result.errors);
    }

    if (result && !this.util.isEmptyObject(result.data)) {
      this.redirect.login();
    }
  }

  checkPasswordValidity() {
    if (this.form.password.value !== this.form.passwordConfirmation.value) {
      this.form.password.setErrors({ noMatch: true });
      this.form.passwordConfirmation.setErrors({ noMatch: true });
      return;
    }

    this.form.password.setErrors(null);
  }

}
