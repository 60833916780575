import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { UtilityService } from '../utility.service';
import { AuthenticationService } from '../authentication.service';
import { IResult } from '@core/Models/IResult';
import { first } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { IExportResult } from '@core/Models/Response/IExportResult';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private api: ApiService, private util: UtilityService, private auth: AuthenticationService) {
    this.init();
  }

  init() {
    this.auth.isAuthorized();
  }

  public async saveTemplate(html, formData): Promise<IResult> {
    let template: IResult = {
      data: {},
      errors: []
    };

    let data = [];
    data['templateContent'] = html;
    data['title'] = formData.title;
    data['templateType'] = formData.templateType;

    try {
      template.data = await this.api.addNewTemplate(data).pipe(first()).toPromise();
    } catch (e) {
      template.errors = [e.error];
    }

    return template;
  }

  public async getTemplate(type) {
    let template: IResult = {
      data: {},
      errors: []
    };

    try {
      template.data = await this.api.getTemplate(type).pipe(first()).toPromise();
    } catch (e) {
      template.errors = [e.error];
    }

    template.data = this.util.keysToCamelCase(template.data);
    return template;
  }

  public async getExportedTemplate(type, element) {
    let template: IExportResult = {
      data: {
        filename: '',
        pdf: ''
      },
      errors: []
    };

    let data = [];
    data['reservation'] = element;
    data['type'] = type;

    try {
      template.data = await this.api.getExportedTemplate(data).pipe(first()).toPromise() as any;
    } catch (e) {
      template.errors = [e.error];
    }

    const byteArray = new Uint8Array(atob(template.data.pdf as string).split('').map(char => char.charCodeAt(0)));
    let blob = new Blob([byteArray], {type: 'application/pdf'});
    FileSaver.saveAs(blob, template.data.filename + ".pdf");

    return template;
  }

  public async checkOldPasswordValidity(oldPassword) {
    let result: IResult = {
      data: {},
      errors: []
    }

    let data = [];
    data['password'] = oldPassword;

    try {
      result.data = await this.api.checkOldPasswordValidity(data).pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    return result;
  }

  public async changePassword(oldPassword, password, passwordConfirmation) {
    let result: IResult = {
      data: {},
      errors: []
    }

    let data = [];
    data['oldPassword'] = oldPassword;
    data['password'] = password;
    data['passwordConfirmation'] = passwordConfirmation;

    try {
      result.data = await this.api.changePassword(data).pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }

    return result;
  }
}


