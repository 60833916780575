import { SnackbarService } from '@core/Services/snackbar.service';
import { OrganizersService } from './../../../core/Services/Data/organizers.service';
import { IOrganizer } from './../../../core/Models/IOrganizer';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-organizer',
  templateUrl: './settings-organizer.component.html',
  styleUrls: ['./settings-organizer.component.scss']
})
export class SettingsOrganizerComponent implements OnInit {

  public organizer: IOrganizer;

  constructor(private organizerService: OrganizersService, private snackbarService: SnackbarService) { }

  ngOnInit() {
    this.loadData();
  }

  private async loadData() {
    const result = await this.organizerService.getCurrentOrganizer();

    if(result && result.errors.length > 0) {
      this.snackbarService.open('Došlo je do pogreške! Probajte učitati stranicu ponovno.');
    }

    if(result && result.data) {
      this.organizer = result.data as IOrganizer;
    }
  }

  public dataChanged($event) {
    if($event == 'edited') {
      this.loadData();
    }
  }
}
