import { FormErrorStateMatcher } from './../../../registration/registration.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UtilityService } from './../../../../core/Services/utility.service';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';



@Component({
  selector: 'app-periods',
  templateUrl: './periods.component.html',
  styleUrls: ['./periods.component.scss'],
})
export class PeriodsComponent implements OnInit, AfterViewInit {
  matcher;
  private period;
  private index = 0;
  saved = true;
  periodAdd: FormGroup;
  @ViewChild('name', { static: false }) name: any;
  @ViewChild('price', { static: false }) price: any;
  @ViewChild('startDate', { static: false }) startDate: any;
  @ViewChild('endDate', { static: false }) endDate: any;
  @Input() inputPeriod;
  @Output() savedPeriods = new EventEmitter<Array<any>>();

  constructor(private util: UtilityService, private cdr: ChangeDetectorRef, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.validation();
  }

  ngAfterViewInit() {
    if(this.inputPeriod && this.inputPeriod.startDate !== undefined && this.inputPeriod.endDate !== undefined)
    {
      this.loadData(this.inputPeriod);
    }
    this.cdr.detectChanges();
  }

  get form() { return this.periodAdd.controls; }

  private validation() {
    this.periodAdd = this.formBuilder.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required]
    });

    this.matcher = new FormErrorStateMatcher();
  }

  private loadData(intervals) {
    this.name.nativeElement.value = intervals.name;
    this.price.nativeElement.value = intervals.price;
    this.startDate.dateValue = intervals.startDate;
    this.endDate.dateValue = intervals.endDate;
    this.saved = true;
  }

  public savePeriod(e) {
    e.preventDefault();
    e.stopPropagation();

    this.onSaveChanges();
    this.savedPeriods.emit(this.period);
    this.saved = true;
  }

  onSaveChanges() {
    this.period = {
      id: this.inputPeriod.id ? this.inputPeriod.id : null,
      inputPeriod: this.inputPeriod,
      name: this.name.nativeElement.value,
      price: this.price.nativeElement.value,
      startDate: this.startDate.dateValue,
      endDate: this.endDate.dateValue,
    };
  }

  formValid() {
    for (const control in this.form) {
      if (this.form[control].invalid) {
        return false;
      }
    }

    return true;
  }

  public onChange() {
    if (this.saved && this.formValid()) {
      this.saved = false;
    } else {
      let nameMatches = this.name.nativeElement.value == this.inputPeriod.name ? true : false;
      let priceMatches = this.price.nativeElement.value == this.inputPeriod.price ? true : false;
      let startDateMatches = this.startDate.dateValue == this.inputPeriod.startDate ? true : false;
      let endDateMatches = this.endDate.dateValue == this.inputPeriod.endDate ? true : false;

      if(nameMatches && priceMatches && startDateMatches && endDateMatches) {
        this.saved = true;
      }
    }
  }

  getMinDate() {
    return new Date();
  }

}
