import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IUser } from '@core/Models/IUser';


@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  private prefix: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  private getRoutePrefix(user: IUser) {
    this.prefix = '/' + user.role + '/';
  }

  public dashboard(user: IUser) {
    if(user == null)
    {
      this.login();
    }

    this.getRoutePrefix(user);
    const destination = this.prefix + 'dashboard';
    this.router.navigate([destination], {relativeTo: this.activatedRoute});
  }

  public login() {
    this.router.navigate(['/login']);
  }


}
