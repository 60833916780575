import { IResult } from './../../Models/IResult';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { UtilityService } from '../utility.service';
import { AuthenticationService } from '../authentication.service';
import { first } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LookupsService {

  constructor(private api: ApiService, private util: UtilityService, private auth: AuthenticationService) {
    this.init();
  }

  init() {
    this.auth.isAuthorized();
  }

  public async getShirtSizeLookup() {
    let lookups;

    try {
      lookups = await this.api.getShirtSizeLookups().pipe(first()).toPromise();
      if (lookups.length === 0) {
        lookups = { errors: 'There are no shirtSize lookups.'};
      }
    } catch (e) {
      lookups = e.error;
    }

    lookups = this.util.keysToCamelCase(lookups);
    return lookups;
  }


  public async getCaptainLookup() {
    let captains;

    try {
      captains = await this.api.getCaptainLookups().pipe(first()).toPromise();
      if (captains.length === 0) {
        captains = { errors: 'There are no captain lookups.'};
      }
    } catch (e) {
      captains = e.error;
    }

    captains = this.util.keysToCamelCase(captains);
    return captains;
  }

  public async getSexLookup() {
    let sex;

    try {
      sex = await this.api.getSexLookups().pipe(first()).toPromise();
      if (sex.length === 0) {
        sex = { errors: 'There are no sex lookups.'};
      }
    } catch (e) {
      sex = e.error;
    }

    sex = this.util.keysToCamelCase(sex);
    return sex;
  }

  public async getMultipleLookup(lookups) {
    let lookupArray: IResult = {
      data: {},
      errors: []
    };

    try {
      lookupArray.data = await this.api.getMultipleLookups(lookups).pipe(first()).toPromise();
    } catch (e) {
      lookupArray.errors = [e.error];
    }

    for (let key in lookupArray.data) {
      if(lookupArray.data.hasOwnProperty(key))
      {
        lookupArray.data[key] = this.util.keysToCamelCase(lookupArray.data[key]);
      }
    }
    return lookupArray;
  }

  public async getWelcomeMessage(captainId): Promise<IResult> {
    let result: IResult = {
      data: {},
      errors: [],
    };

    try {
      result.data = await this.api.getWelcomeMessage(captainId).pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    result.data = this.util.keysToCamelCase(result.data);
    return result;
  }

  public async getRaceName(raceId): Promise<IResult> {
    let result: IResult = {
      data: {},
      errors: [],
    };

    try {
      result.data = await this.api.getRaceName(raceId).pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    result.data = this.util.keysToCamelCase(result.data);
    return result;
  }

  public async getTeamName(captainId): Promise<IResult> {
    let result: IResult = {
      data: {},
      errors: [],
    };

    try {
      result.data = await this.api.getTeamName(captainId).pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    result.data = this.util.keysToCamelCase(result.data);
    return result;
  }
}
