import { SnackbarService } from '@core/Services/snackbar.service';
import { CaptainsService } from './../../../core/Services/Data/captains.service';
import { ICaptain } from './../../../core/Models/ICaptain';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-captain',
  templateUrl: './settings-captain.component.html',
  styleUrls: ['./settings-captain.component.scss']
})
export class SettingsCaptainComponent implements OnInit {
  public captain: ICaptain;

  constructor(private captainService: CaptainsService, private snackbarService: SnackbarService) { }

  ngOnInit() {
    this.loadData();
  }

  public async loadData() {
    const result = await this.captainService.getCurrentCaptain();

    if(result && result.errors.length > 0) {
      this.snackbarService.open('Neuspješno dohvaćanje podataka, molimo ponovno učitajte stranicu.');
    }

    if(result && result.data) {
      this.captain = result.data as ICaptain;
    }
  }


}
