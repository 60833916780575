import { AuthenticationService } from './../../../core/Services/authentication.service';
import { SnackbarService } from './../../../core/Services/snackbar.service';
import { LookupsService } from './../../../core/Services/Data/lookups.service';
import { Component, OnInit, Optional, Inject, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { RunnersService } from '@core/Services/Data/runners.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogComponent } from '../dialog.component';
import { IRunner } from '@core/Models/IRunner';
import { ICaptain } from '@core/Models/ICaptain';
import { ILookup } from '@core/Models/ISex';
import { UtilityService } from '@core/Services/utility.service';
import { IRegistration } from '@core/Models/IRegistration';
import { FormErrorStateMatcher } from '@components/shared/form-error-state-matcher/form-error-state-matcher';



@Component({
  selector: 'app-runner-add',
  templateUrl: './runner-add.component.html',
  styleUrls: ['./runner-add.component.scss'],
})
export class RunnerAddComponent implements OnInit {
  saving;
  role;
  matcher;
  dataToEdit: IRunner;
  edit = false;
  public runnerAdd: FormGroup;
  public captain = {
    list: [],
    filtered: []
  }

  public shirtSize = {
    list: []
  }

  public sex = {
    list: []
  }
  public organizer = {
    all: [],
    list: [],
    filtered: []
  }
  @ViewChild('selectCaptain', { static: false }) selectCaptain: any;
  @Input() microsite: IRegistration | undefined;
  @Output() done = new EventEmitter<boolean>();

  constructor(private auth: AuthenticationService,
    private util: UtilityService,
    private runnerService: RunnersService,
    private lookupService: LookupsService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DialogComponent>,
    private snackBarService: SnackbarService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: IRunner) {
    this.dataToEdit = dialogData;
  }


  ngOnInit() {
    this.loadData();
    this.validation();
    this.role = this.auth.getRole();

    if (this.role == 'captain') {
      this.runnerAdd.controls['captain'].disable();
    }
  }
  
  async loadData() {
    const lookups: any = await this.lookupService.getMultipleLookup('captain,sex,shirtSize,organizer');

    if (lookups && lookups.errors.length > 0) {
      this.snackBarService.open("Nešto je pošlo po krivu. Probajte ponovno učitati stranicu!");
      return false;
    } 

    this.captain.list = lookups.data.captain;
    this.shirtSize.list = lookups.data.shirtSize;
    this.sex.list = lookups.data.sex;

    if (!this.util.isEmptyObject(this.dataToEdit)) {
      this.populate();
    }

    if (!(this.microsite == undefined) && (this.microsite && this.microsite.captainId)) {
      this.setUpMicrosite(this.microsite.captainId);
    }
    if(lookups.data.organizer) {
      this.setDropdowns(lookups);
    }
  }
  public findCaptain(organizer) {
    this.captain.filtered  = this.captain.list.filter(cap => 
      cap.organizerId == organizer.id
    );
    
  }
  private setDropdowns(lookups) {
    this.organizer.all = lookups.data.organizer;
    this.organizer.list = lookups.data.organizer;
    this.organizer.filtered = lookups.data.organizer;
    this.runnerAdd.patchValue({
      organizer: this.organizer
    })
  }
  optionFilter(value, option: string) {
    if (option === 'captain') {
      this.captain.filtered = this.captainFilterOptions(value, this.captain.list);
    }
  }

  private populate() {
    let filteredCaptain = this.captain.list.filter(
      cap => cap.id == this.dataToEdit.captainId
    );

    let filteredSex = this.sex.list.filter(
      s => s.name == this.dataToEdit.sex
    );

    let filteredShirtSize = this.shirtSize.list.filter(
      size => size.name == this.dataToEdit.shirtSize
    );

    let captain = filteredCaptain[0] as ICaptain;
    let sex = filteredSex[0] as ILookup;
    let shirtSize = filteredShirtSize[0] as ILookup;

    this.form.captain.setValue(captain);
    this.form.name.setValue(this.dataToEdit.name);
    this.form.lastName.setValue(this.dataToEdit.lastName);
    this.form.email.setValue(this.dataToEdit.email);
    this.form.dateOfBirth.setValue(this.dataToEdit.dateOfBirth);
    this.form.pin.setValue(this.dataToEdit.pin);
    this.form.phone.setValue(this.dataToEdit.phone);
    this.form.shirtSize.setValue(shirtSize);
    this.form.sex.setValue(sex);
    this.form.pb5.setValue(this.dataToEdit.pb5);
    this.form.pb10.setValue(this.dataToEdit.pb10);
    this.form.pb21.setValue(this.dataToEdit.pb21);
    this.form.pb42.setValue(this.dataToEdit.pb42);

    this.edit = true;
  }

  private validation() {
    this.runnerAdd = this.formBuilder.group({
      captain: ['', Validators.required],
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      dateOfBirth: ['', Validators.required],
      pin: ['', [Validators.required, Validators.min(99999), Validators.max(999999999999)]],
      phone: ['', Validators.required],
      shirtSize: ['', Validators.required],
      sex: ['', Validators.required],
      pb5: ['', Validators.nullValidator],
      pb10: ['', Validators.nullValidator],
      pb21: ['', Validators.nullValidator],
      pb42: ['', Validators.nullValidator],
      organizer: ['', Validators.required]
    });

    this.matcher = new FormErrorStateMatcher();
  }

  get form() { return this.runnerAdd.controls; }


  async onClickSubmit(value: any) {
    let result;
    this.saving = true;

    for (const control in this.form) {
      if (this.form[control].invalid) {
        this.saving = false;
        return;
      }
    }

    if (!(this.microsite == undefined) && (this.microsite && this.microsite.reservationId)) {
      let data = this.runnerAdd.getRawValue();
      result = await this.runnerService.registerNewRunner(data, this.microsite.reservationId);
    }
    if (this.edit) {
      value.id = this.dataToEdit.id;
      result = await this.runnerService.editRunner(value);
    }
    if(!this.edit && this.microsite == undefined) {
      result = await this.runnerService.addNewRunner(value);
    }

    if (result && result.errors.length > 0) {
      this.snackBarService.open("Nešto je pošlo po krivu. Molimo ponovite unos.");
      if(this.microsite == undefined)
      {
        this.dialogRef.close({ result: false });
      }
      this.saving = false;
      return;
    }
    if (result && result.data && !this.edit) {
      this.snackBarService.open("Uspešno ste dodali takmičara - " + value.name + " " + value.lastName);
      if(this.microsite == undefined)
      {
        this.dialogRef.close({ result: true });
      }
      this.saving = false;
      this.done.emit(true);
      return;
    }

    if (this.edit && result && result.data && result.errors.length == 0) {
      this.snackBarService.open("Uspešno ste izmenili takmičara - " + value.name + " " + value.lastName);
      this.dialogRef.close({ result: true });
    }

  }

  captainFilterOptions(value, list) {
    let filter = value.toLowerCase();
    return list.filter(option => option.teamName.toLocaleLowerCase().startsWith(filter));
  }

  setUpMicrosite(captainId) {
    let filteredCaptain = this.captain.list.filter(
      cap => cap.id == captainId
    );
    let captain = filteredCaptain[0] as ICaptain;

    this.form.captain.setValue(captain);
    this.form.captain.disable();
  }


}

