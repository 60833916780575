import { UtilityService } from './../../../core/Services/utility.service';
import { Component, OnInit, Input, Optional, Inject, SimpleChange, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, ErrorStateMatcher } from '@angular/material';
import { DialogComponent } from '../dialog.component';
import { SnackbarService } from '@core/Services/snackbar.service';
import { IResult } from '@core/Models/IResult';
import { IOrganizer } from '@core/Models/IOrganizer';
import { OrganizersService } from '@core/Services/Data/organizers.service';
import { EventEmitter } from '@angular/core';
import { FormErrorStateMatcher } from '@components/shared/form-error-state-matcher/form-error-state-matcher';


@Component({
  selector: 'app-organizer-add',
  templateUrl: './organizer-add.component.html',
  styleUrls: ['./organizer-add.component.scss']
})
export class OrganizerAddComponent implements OnInit {
  submitted = false;
  matcher;
  edit = false;
  public organizerAdd: FormGroup;
  @Input() dataToEdit: IOrganizer;
  @Output() dataChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DialogComponent>,
    private snackBarService: SnackbarService,
    private organizerService: OrganizersService,
    private util: UtilityService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: IOrganizer) {
    this.dataToEdit = dialogData;
  }

  ngOnInit() {
    this.validation();

    if (this.dataToEdit) {
      this.populate(this.dataToEdit);
    }
  }

  private populate(data: IOrganizer) {
    this.form.name.setValue(data.name);
    this.form.legalName.setValue(data.legalName);
    this.form.email.setValue(data.email);
    this.form.subdomain.setValue(data.subdomain);
    this.form.pin.setValue(data.pin);
    this.form.pinOther.setValue(data.pinOther);
    this.form.address.setValue(data.address);
    this.form.city.setValue(data.city);
    this.form.postcode.setValue(data.postcode);
    this.form.country.setValue(data.country);
    this.form.giroAccount.setValue(data.giroAccount);
    this.form.phone.setValue(data.phone);
    this.form.website.setValue(data.website);
    this.form.supportLink.setValue(data.supportLink);
    this.form.tosLink.setValue(data.tosLink);
    this.form.currency.setValue(data.currency);
    this.form.vatLabel.setValue(data.vatLabel);
    this.form.vatPercent.setValue(data.vatPercent);

    this.edit = true;

    this.form.password.setValidators(null);
    this.form.password.updateValueAndValidity();
    this.form.passwordConfirmation.setValidators(null);
    this.form.passwordConfirmation.updateValueAndValidity();

    this.form.email.disable();
    this.form.subdomain.disable();
  }

  ngOnChanges(changes: SimpleChange) {
    let change: any = changes;
    if(change && change.dataToEdit.currentValue)
    {
      this.dataToEdit = change.dataToEdit.currentValue;
    }
  }

  private validation() {
    this.organizerAdd = this.formBuilder.group({
      name: ['', Validators.required],
      legalName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
      passwordConfirmation: ['', Validators.required],
      subdomain: ['', Validators.required],
      pin: ['', Validators.required],
      pinOther: ['', Validators.nullValidator],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
      country: ['', Validators.required],
      giroAccount: ['', Validators.required],
      phone: ['', Validators.required],
      website: ['', Validators.nullValidator],
      supportLink: ['', Validators.nullValidator],
      tosLink: ['', Validators.nullValidator],
      currency: ['', Validators.required],
      vatLabel: ['', Validators.required],
      vatPercent: ['', Validators.required],
    });

    this.matcher = new FormErrorStateMatcher();
  }

  get form() {
    if(this.organizerAdd && this.organizerAdd.controls) {
      return this.organizerAdd.controls;
    }
  }

  async onClickSubmit(data: any) {
    let result: IResult;
    this.submitted = true;
    for (const control in this.form) {
      if (this.form[control].invalid) {
        return;
      }
    }

    if (this.edit) {
      data.id = this.dataToEdit.id;
      result = await this.organizerService.editOrganizer(data);
    } else {
      result = await this.organizerService.addNewOrganizer(data);
    }

    if (result && result.errors.length > 0) {
      this.snackBarService.open("Nešto je pošlo po krivu. Probajte ponovno učitati stranicu.");
      this.submitted = false;
      return;
    }

    if (result && !this.edit) {
      this.snackBarService.open("Uspešno ste dodali organizatora - " + data.name);
      if(this.util.isEmptyObject(this.dialogRef)) {
        return;
      }
      this.dialogRef.close({ result: true });
    }

    if (this.edit) {
      this.snackBarService.open("Uspešno ste izmenili organizatora - " + data.name);
      if(this.util.isEmptyObject(this.dialogRef)) {
        this.dataChange.emit('edited');
        return;
      }
      this.dialogRef.close({ result: true });
    }
  }

  checkPasswordValidity() {
    if (this.form.password.value !== this.form.passwordConfirmation.value) {
      this.form.password.setErrors({ noMatch: true });
      this.form.passwordConfirmation.setErrors({ noMatch: true });
      return;
    }

    this.form.password.setErrors(null);
  }
}
