import { MatSnackBar } from '@angular/material';
import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(public snackBar: MatSnackBar, private zone: NgZone) { }

  public open(message, action = 'OK', duration = 6000) {
    this.zone.run(() => {
      this.snackBar.open(message, action, {duration});
    })
  }
}
