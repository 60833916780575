import { ReservationsService } from '@core/Services/Data/reservations.service';
import { ModalService } from '@core/Services/modal.service';
import { SnackbarService } from './../../../core/Services/snackbar.service';
import { SettingsService } from './../../../core/Services/Data/settings.service';
import { UtilityService } from './../../../core/Services/utility.service';
import { Component, ViewChild, Input, SimpleChanges, SimpleChange, OnChanges, ElementRef, OnInit, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatSortable, MatTable, MatTab } from '@angular/material';
import { AuthenticationService } from '@core/Services/authentication.service';
import { FormControl } from '@angular/forms';
import { IResult } from '@core/Models/IResult';
import * as moment from 'moment';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnChanges, AfterViewInit {
  empty = false;
  loading = false;
  content;
  sortedByRole: boolean | undefined;
  hidden: string;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  @ViewChild(MatSort, { static: false }) set sorter(content: ElementRef) {
    this.content = content;
    if (this.content) {
      this.filteredDataSource.sort = this.content;
      this.sortByRole();
    }
  };
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @Input() sort: boolean = true;
  @Input() toDisplay: any;
  @Input() export: boolean = true;
  @Input() columns: Array<string>;
  @Input() pageSize = 25;
  @Input() pageSizeOptions: number[] = [25, 50, 100, 200];
  @Output() selectedItems = new EventEmitter<Array<any>>();
  @Output() edit = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();
  @Output() paymentChange = new EventEmitter<boolean>();


  public checkedItems: any[] = [];
  public dataSource: [];
  public filteredDataSource: MatTableDataSource<[]>;
  public lastFiltered: [];
  public lastFilteredKey: string;
  displayedColumns: string[];

  /* #region FILTERS */

  _emailFilter = '';
  public get emailFilter(): string {
    return this._emailFilter;
  }
  public set emailFilter(value: string) {
    this._emailFilter = value;
    this.filteredDataSource.data = this.emailFilter ? this.filterData('email', this.emailFilter) : this.dataSource;
  }

  _teamNameFilter = '';
  public get teamNameFilter(): string {
    return this._teamNameFilter;
  }
  public set teamNameFilter(value: string) {
    this._teamNameFilter = value;
    this.filteredDataSource.data = this.teamNameFilter ? this.filterData('teamName', this.teamNameFilter) : this.dataSource;
  }

  _organizerNameFilter = '';
  public get organizerNameFilter(): string {
    return this._organizerNameFilter;
  }
  public set organizerNameFilter(value: string) {
    this._organizerNameFilter = value;
    this.filteredDataSource.data = this.organizerNameFilter ? this.filterData('organizerName', this.organizerNameFilter) : this.dataSource;
  }

  _nameFilter = '';
  public get nameFilter(): string {
    return this._nameFilter;
  }
  public set nameFilter(value: string) {
    this._nameFilter = value;
    this.filteredDataSource.data = this.nameFilter ? this.filterData('name', this.nameFilter) : this.dataSource;
  }

  _lastNameFilter = '';
  public get lastNameFilter(): string {
    return this._lastNameFilter;
  }
  public set lastNameFilter(value: string) {
    this._lastNameFilter = value;
    this.filteredDataSource.data = this.lastNameFilter ? this.filterData('lastName', this.lastNameFilter) : this.dataSource;
  }

  _legalNameFilter = '';
  public get legalNameFilter(): string {
    return this._legalNameFilter;
  }
  public set legalNameFilter(value: string) {
    this._legalNameFilter = value;
    this.filteredDataSource.data = this.legalNameFilter ? this.filterData('legalName', this.legalNameFilter) : this.dataSource;
  }

  _shirtSizeFilter = '';
  public get shirtSizeFilter(): string {
    return this._shirtSizeFilter;
  }
  public set shirtSizeFilter(value: string) {
    this._shirtSizeFilter = value;
    this.filteredDataSource.data = this.shirtSizeFilter ? this.filterData('shirtSize', this.shirtSizeFilter) : this.dataSource;
  }

  _sexFilter = '';
  public get sexFilter(): string {
    return this._sexFilter;
  }
  public set sexFilter(value: string) {
    this._sexFilter = value;
    this.filteredDataSource.data = this.sexFilter ? this.filterData('sex', this.sexFilter) : this.dataSource;
  }

  _subdomainFilter = '';
  public get subdomainFilter(): string {
    return this._subdomainFilter;
  }
  public set subdomainFilter(value: string) {
    this._subdomainFilter = value;
    this.filteredDataSource.data = this.subdomainFilter ? this.filterData('subdomain', this.subdomainFilter) : this.dataSource;
  }

  _companyNameFilter = '';
  public get companyNameFilter(): string {
    return this._companyNameFilter;
  }
  public set companyNameFilter(value: string) {
    this._companyNameFilter = value;
    this.filteredDataSource.data = this.companyNameFilter ? this.filterData('companyName', this.companyNameFilter) : this.dataSource;
  }

  _captainFilter = '';
  public get captainFilter(): string {
    return this._captainFilter;
  }
  public set captainFilter(value: string) {
    this._captainFilter = value;
    this.filteredDataSource.data = this.captainFilter ? this.filterData('captain', this.captainFilter) : this.dataSource;
  }

  _cityFilter = '';
  public get cityFilter(): string {
    return this._cityFilter;
  }
  public set cityFilter(value: string) {
    this._cityFilter = value;
    this.filteredDataSource.data = this.cityFilter ? this.filterData('city', this.cityFilter) : this.dataSource;
  }

  _countryFilter = '';
  public get countryFilter(): string {
    return this._countryFilter;
  }
  public set countryFilter(value: string) {
    this._countryFilter = value;
    this.filteredDataSource.data = this.countryFilter ? this.filterData('country', this.countryFilter) : this.dataSource;
  }

  _runnerFilter = '';
  public get runnerFilter(): string {
    return this._runnerFilter;
  }
  public set runnerFilter(value: string) {
    this._runnerFilter = value;
    this.filteredDataSource.data = this.runnerFilter ? this.filterData('runner', this.runnerFilter) : this.dataSource;
  }

  _admissionsFilter = '';
  public get admissionsFilter(): string {
    return this._admissionsFilter;
  }
  public set admissionsFilter(value: string) {
    this._admissionsFilter = value;
    this.filteredDataSource.data = this.admissionsFilter ? this.filterData('admissions', this.admissionsFilter) : this.dataSource;
  }

  _businessFilter = '';
  public get businessFilter(): string {
    return this._businessFilter;
  }
  public set businessFilter(value: string) {
    this._businessFilter = value;
    this.filteredDataSource.data = this.businessFilter ? this.filterData('business', this.businessFilter) : this.dataSource;
  }

  _raceNameFilter = '';
  public get raceNameFilter(): string {
    return this._raceNameFilter;
  }
  public set raceNameFilter(value: string) {
    this._raceNameFilter = value;
    this.filteredDataSource.data = this.raceNameFilter ? this.filterData('raceName', this.raceNameFilter) : this.dataSource;
  }

  _startplacesFilter = '';
  public get startplacesFilter(): string {
    return this._startplacesFilter;
  }
  public set startplacesFilter(value: string) {
    this._startplacesFilter = value;
    this.filteredDataSource.data = this.startplacesFilter ? this.filterData('startplaces', this.startplacesFilter) : this.dataSource;
  }

  _amountFilter = '';
  public get amountFilter(): string {
    return this._amountFilter;
  }
  public set amountFilter(value: string) {
    this._amountFilter = value;
    this.filteredDataSource.data = this.amountFilter ? this.filterData('amount', this.amountFilter) : this.dataSource;
  }

  _paymentStatus = '';
  public get paymentStatus(): string {
    return this._paymentStatus;
  }
  public set paymentStatus(value: string) {
    this._paymentStatus = value;
    this.filteredDataSource.data = this.paymentStatus ? this.filterData('amount', this.paymentStatus) : this.dataSource;
  }
  /* #endregion */

  constructor(private auth: AuthenticationService, private util: UtilityService, private settingsService: SettingsService,
    private snackbarService: SnackbarService, private dialogService: ModalService, private reservationService: ReservationsService, private cdr: ChangeDetectorRef) {
      this.filteredDataSource = new MatTableDataSource();
    }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getHiddenColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change: SimpleChange = changes.toDisplay;
    if (change.currentValue != null && change.currentValue != undefined || !this.util.isEmptyObject(change.currentValue)) {
      this.dataSource = change.currentValue;
      this.loadData(change.currentValue);
    }
  }

  private async loadData(dataSet = null) {
    this.loading = true;
    if (dataSet == null || dataSet.length < 1 || this.util.isEmptyObject(dataSet)) {

      this.loading = false;
      this.empty = true;
      this.filteredDataSource = new MatTableDataSource();

      if(this.table) {
        this.table.renderRows();
        this.cdr.detectChanges();
      }

      return;
    } else {
      this.empty = false;
    }

    this.displayedColumns = this.columns;
    this.filteredDataSource = new MatTableDataSource(dataSet);
    this.lastFiltered = dataSet;
    this.filteredDataSource.paginator = this.paginator;

    this.filteredDataSource.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'date': return new Date(item.date);
        case 'reservationDate': return new Date(item.createdAt);
        case 'admissions': return item.reservedPlaces;
        case 'captain': return item.lastName;
        default: return item[property];
      }
    }
    this.loading = false;
  }

  filterData(column: string, filterBy: string) {
    let filteredData;
    filterBy = filterBy.toLocaleLowerCase();
    if (column === 'admissions') {
      column = 'reservedPlaces';
    }

    filteredData = this.filteredDataSource.data.filter((data: any) => {
      if (column == 'captain') {
        let value = data['name'] + ' ' + data['lastName'];
        return value.toLocaleLowerCase().includes(filterBy) === true
      }
      if (column == 'runner') {
        let value = data['runnerName'] + ' ' + data['runnerLastName'];
        return value.toLocaleLowerCase().includes(filterBy) === true
      }
      if (Number(data[column])) {
        let value = String(data[column]);
        return value.startsWith(filterBy) === true;
      }
      if (typeof data[column] == "string") {
        return data[column].toLocaleLowerCase().startsWith(filterBy) === true
      }
    });

    if (filteredData.length < 1) {
      filteredData = this.lastFiltered
    } else {
      this.lastFiltered = filteredData;
    }

    return filteredData;
  }


  sortByRole() {
    if (this.sortedByRole === undefined || !this.sortedByRole) {
      if (this.auth.getRole() === 'organizer') {
        const sortByTeamName = { id: 'teamName', start: 'asc', disableClear: true };
        this.filteredDataSource.sort.sort(sortByTeamName as MatSortable);
        this.sortedByRole = true;
        this.cdr.detectChanges();
      }
      if (this.auth.getRole() === 'admin') {
        const sortByOrganizer = { id: 'organizerName', start: 'asc', disableClear: true };
        this.filteredDataSource.sort.sort(sortByOrganizer as MatSortable);
        this.sortedByRole = true;
        this.cdr.detectChanges();
      }
    }
  }

  checkedItem(item) {
    let found = Object.keys(this.checkedItems).find(k => this.checkedItems[k] == item);
    if (found == null) {
      this.checkedItems.push(item);
    } else {
      let index = Number(found);
      this.checkedItems.splice(index, 1);
    }

    this.selectedItems.emit(this.checkedItems);
  }

  editEvent(data) {
    this.edit.next(data);
  }

  deleteEvent(data) {
    this.delete.next(data);
  }

  isPaid(status) {
    return status ? 'primary' : 'warn';
  }

  public async billing(type, element) {
    let result: IResult;

    result = await this.settingsService.getExportedTemplate(type, element);

    if (result && result.errors.length > 0) {
      this.snackbarService.open('Ne možemo u ovom času dohvatiti ispis. Molimo pokušajte kasnije.');
    }

    if (result && result.data) {
      this.snackbarService.open('PDF je preuzet. Provjerite preuzimanja.');
    }
  }

  public getClass() {
    if (!this.export || !(this.auth.getRole() == 'organizer' || this.auth.getRole() == "admin")) {
      return 'btn-export hidden';
    }
    else {
      return 'btn-export';
    }
  }

  public async changePaymentStatus(data) {
    if (this.auth.getRole() == 'organizer') {
      this.dialogService.openConfirmationDialog('payment-change').subscribe(async res => {
        if (!res && res !== '') {
          this.snackbarService.open('Nije moguće promijeniti status plaćanja. Pokušajte ponovno kasnije.');
        }

        if (!res.result) {
          return;
        }

        let result = await this.reservationService.setPaymentStatus(data.id);
        this.paymentChange.emit(true);
      });
    }
  }

  getHiddenColumns() {
    for (let key in this.displayedColumns) {
      if (this.displayedColumns[key] == "invoice" || this.displayedColumns[key] == "billing" || this.displayedColumns[key] == "options") {
        this.hidden = this.hidden == undefined ? key : this.hidden + "," + key;
      }
    };

    this.cdr.detectChanges();
  }
}
