import { DebugInterceptor } from './core/Services/Interceptors/debug.interceptor';
import { TokenInterceptor } from './core/Services/Interceptors/token.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


// Angular Material Components
import { MatCheckboxModule, MatNativeDateModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';

// custom imports
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from '@components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationComponent } from '@components/registration/registration.component';
import { AuthenticationService } from '@core/Services/authentication.service';
import { DashboardAdminComponent } from './components/dashboard/dashboard-admin/dashboard-admin.component';
import { DashboardCaptainComponent } from './components/dashboard/dashboard-captain/dashboard-captain.component';
import { DashboardOrganizerComponent } from './components/dashboard/dashboard-organizer/dashboard-organizer.component';
import { RedirectService } from '@core/Services/redirect.service';
import { RegistrationRunnerComponent } from './components/registration/registration-runner/registration-runner.component';
import { RacesComponent } from './components/races/races.component';
import { RunnersComponent } from './components/runners/runners.component';
import { HomeCaptainComponent } from './components/home/home-captain/home-captain.component';
import { HomeOrganizerComponent } from './components/home/home-organizer/home-organizer.component';
import { HomeAdminComponent } from './components/home/home-admin/home-admin.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { OrganizerAddComponent } from './components/dialog/organizer-add/organizer-add.component';
import { TeamsComponent } from './components/teams/teams.component';
import { DataTableComponent } from './components/shared/data-table/data-table.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ReservationsComponent } from './components/reservations/reservations.component';
import { RunnerAddComponent } from './components/dialog/runner-add/runner-add.component';
import { RaceAddComponent } from './components/dialog/race-add/race-add.component';
import { CaptainAddComponent } from './components/dialog/captain-add/captain-add.component';
import { ReservationAddComponent } from './components/dialog/reservation-add/reservation-add.component';
import { InventoryComponent } from './components/shared/inventory/inventory.component';
import { PeriodsComponent } from './components/shared/inventory/periods/periods.component';
import { PaymentsComponent } from './components/shared/payments/payments.component';
import { ReservationDetailComponent } from './components/reservations/reservation-detail/reservation-detail.component';
import { DocumentEditorComponent } from './components/shared/document-editor/document-editor.component';
import { SettingsCaptainComponent } from './components/settings/settings-captain/settings-captain.component';
import { SettingsAdminComponent } from './components/settings/settings-admin/settings-admin.component';
import { SettingsOrganizerComponent } from './components/settings/settings-organizer/settings-organizer.component';
import { RunnersSelectComponent } from './components/dialog/runners-select/runners-select.component';
import { ConfirmationComponent } from './components/dialog/confirmation/confirmation.component';
import { DragAndDropDirective } from './directives/dragAndDrop/drag-and-drop.directive';
import { LogoUploadComponent } from './components/shared/logo-upload/logo-upload.component';
import { DatePickerComponent } from './components/shared/date-picker/date-picker.component';
import { EntryListComponent } from './components/entry-list/entry-list.component';
import { ChartComponent } from './components/shared/chart/chart.component';
import { ProgressbarComponent } from './components/shared/progressbar/progressbar.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { AdminLoginComponent } from './components/login/admin-login/admin-login.component';
import { OrganizersComponent } from './components/organizers/organizers.component';
import { PasswordChangeComponent } from './components/shared/password-change/password-change.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    DashboardAdminComponent,
    DashboardCaptainComponent,
    DashboardOrganizerComponent,
    RegistrationRunnerComponent,
    RacesComponent,
    RunnersComponent,
    HomeCaptainComponent,
    HomeOrganizerComponent,
    HomeAdminComponent,
    DialogComponent,
    OrganizerAddComponent,
    TeamsComponent,
    DataTableComponent,
    ReservationsComponent,
    RunnerAddComponent,
    RaceAddComponent,
    CaptainAddComponent,
    ReservationAddComponent,
    InventoryComponent,
    PeriodsComponent,
    PaymentsComponent,
    ReservationDetailComponent,
    DocumentEditorComponent,
    SettingsCaptainComponent,
    SettingsAdminComponent,
    SettingsOrganizerComponent,
    RunnersSelectComponent,
    ConfirmationComponent,
    LogoUploadComponent,

    // Directives
    DragAndDropDirective,

    DatePickerComponent,

    EntryListComponent,

    ChartComponent,

    ProgressbarComponent,

    AdminLoginComponent,

    OrganizersComponent,

    PasswordChangeComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatTableExporterModule,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    GoogleChartsModule,
    // angularMaterial
    BrowserModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
  ],
  providers: [
    AuthenticationService,
    RedirectService,
    MatDatepickerModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  entryComponents: [
    OrganizerAddComponent,
    RunnerAddComponent,
    RaceAddComponent,
    CaptainAddComponent,
    ReservationAddComponent,
    RunnersSelectComponent,
    ConfirmationComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


