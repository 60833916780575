import { AuthenticationService } from '@core/services/authentication.service';
import { SnackbarService } from '@core/Services/snackbar.service';
import { IResult } from '@core/Models/IResult';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { CaptainsService } from '@core/Services/Data/captains.service';
import { ModalService } from '@core/Services/modal.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit, OnDestroy {
  selectedRace = new Subject<string>();
  selectedTeams: any;
  public setColumns: string[] = [
    'position',
    'teamName',
    'companyName',
    'captain',
    'email',
    'phone',
    'admissions',
    'business',
    'totalEmployees',
    'options'
  ];

  constructor(private captainService: CaptainsService, private dialog: ModalService, private snackbarService: SnackbarService,
    private auth: AuthenticationService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if(this.auth.getRole() == "admin") {
      this.setColumns.splice(7, 2);
      this.setColumns.splice(1, 0, 'organizerName');
    }

    this.loadData();
  }

  selectRace($event) {
    this.loadData($event);
    this.selectedRace.next($event);
  }

  ngOnDestroy() {
    this.selectedRace.complete();
  }

  public refresh() {
    this.loadData();
  }

  async loadData(race = null) {
    let response;

    if(!race){
      response = await this.captainService.getAllTeams();
    } else {
      response = await this.captainService.getAllTeamsByRaceId(race);
    }

    if(response && response.errors.length > 0) {
      this.snackbarService.open('Nemožemo dohvatiti timove. Molimo pokušajte kasnije.');
    }

    if(response && response.data) {
      this.selectedTeams = response.data;
      this.cdr.detectChanges();
    }
  }

  editTeam($event) {
    this.dialog.openCaptainAdd($event).subscribe(async (res: any) => {
      if(res === undefined || !res.result) {
        return;
      }

      this.loadData();
    });
  }

  deleteTeam($event) {
    let result: IResult;

    this.dialog.openConfirmationDialog('team-delete').subscribe(async (res: any) => {
      if (res === undefined || !res.result) {
        return;
      }

      result = await this.captainService.deleteCaptain($event.id);

      if (result && result.errors.length > 0) {
        this.snackbarService.open('Dogodila se pogreška! Ako se ponovi, kontaktirajte sistem administratora.');
        return;
      }

      if (result && result.data) {
        this.snackbarService.open('Uspješno ste obrisali tim.');
        this.loadData();
        return;
      }
    });
  }
}
