import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit {
  isZero = false;
  width: Array<any> = [];
  @Input() values;
  @Input() maxValue;

  colors = ['#00385D', '#ABE0F9', '#5D2500', '#F9C4AB'];
  lastColor;

  constructor() { }

  ngOnInit() {
    this.values = this.values.split(',');
    this.values.sort(function (a,b) { return a-b});
    this.values.forEach(value => {
      let percent = value/this.maxValue  as any;
      if(percent == 0) {
        this.isZero = true;
        this.width.push(0);
      } else {
        this.width.push(percent*100);
      }

      let sum = this.width.reduce((acc, cur) => acc + cur, 0);
      if(sum >= 100)
       {
        this.width = [100];
       }
    });
  }

  getColor(i) {
    if(i > this.colors.length) {
      i = 0;
    }
    this.lastColor = this.lastColor == this.colors[i] ? this.colors[i++] : this.colors [i];

    return this.lastColor;
  }

  isFull() {
    return this.width.reduce((acc, cur) => acc + cur, 0) == 100 ? false : true;
  }



}
