import { LookupsService } from '@core/Services/Data/lookups.service';
import { ReservationsService } from './../../../core/Services/Data/reservations.service';
import { CaptainsService } from '@core/Services/Data/captains.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RunnersService } from '@core/Services/Data/runners.service';
import { SnackbarService } from '@core/Services/snackbar.service';
import { RacesService } from '@core/Services/Data/races.service';
import { debug } from 'util';

@Component({
  selector: 'app-registration-runner',
  templateUrl: './registration-runner.component.html',
  styleUrls: ['./registration-runner.component.scss']
})
export class RegistrationRunnerComponent implements OnInit {
  public state = null;
  public welcomeMessage: string;
  public raceName: string;
  public teamName: string;
  public registering: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private snackbarService: SnackbarService, private router: Router, private lookupService: LookupsService) {
    this.state = this.activatedRoute.snapshot.paramMap.get('hash');
    this.state = JSON.parse(atob(this.state));
    if (this.state == undefined && this.state == null) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.loadData();
  }

  private async loadData() {
    const message: any = await this.lookupService.getWelcomeMessage(this.state.captainId);
    const teamName: any = await this.lookupService.getTeamName(this.state.captainId);
    const raceName: any = await this.lookupService.getRaceName(this.state.raceId);

    if(message && message.data && message.data.customWelcome) {
      this.welcomeMessage = message.data.customWelcome;
    }
    if(raceName && raceName.data && raceName.data.name) {
      this.raceName = raceName.data.name;
    }
    if(teamName && teamName.data && teamName.data.teamName) {
      this.teamName = teamName.data.teamName;
    }

  }

  public isDone() {
    this.registering = false;
  }

}
