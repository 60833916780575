import { SnackbarService } from '@core/Services/snackbar.service';
import { ModalService } from '@core/Services/modal.service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import {Location} from '@angular/common';
import { OrganizersService } from '@core/Services/Data/organizers.service';

@Component({
  selector: 'app-home-captain',
  templateUrl: './home-captain.component.html',
  styleUrls: ['./home-captain.component.scss']
})
export class HomeCaptainComponent implements OnInit, AfterViewInit {
  router: string;
  opened = true;
  childComponent;
  organizer;
  @ViewChild(RouterOutlet, { static: false }) outlet;

  constructor(private _router: Router, private modal: ModalService, private location: Location,
    private snackbarService: SnackbarService, private organizerService: OrganizersService) {
    _router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.router = val.url;
      }
    });
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    if(this.outlet && this.outlet.component) {
      this.childComponent = this.outlet.component;
    }
  }

  private async loadData() {
    let result = await this.organizerService.getOrganizerContact();

    if(result && result.errors.length > 0) {
      this.snackbarService.open('Nešto je pošlo po krivu. Molimo probajte ponovno učitati stranicu.');
    }

    if(result && result.data) {
      this.organizer = result.data;
    }
  }

  openModal(modal) {
    switch(modal) {
      case "runners": {
        this.modal.openRunnerAdd().subscribe((res: any) => {
          if(!res || res === undefined || res === null) {
            return;
          }

          this.childComponent.refresh();
        });
        break;
      }
      case "reservations": {
        this.modal.openReservationAdd().subscribe((res: any) => {
          if(!res || res === undefined || res === null) {
            return;
          }

          this.childComponent.refresh();
        });
        break;
      }
    }
  }


  backButton() {
    this.location.back();
  }
}
