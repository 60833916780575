import { UtilityService } from '@core/Services/utility.service';
import { NavigationExtras, ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from './../../../core/Services/Data/dashboard.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard-organizer',
  templateUrl: './dashboard-organizer.component.html',
  styleUrls: ['./dashboard-organizer.component.scss']
})
export class DashboardOrganizerComponent implements OnInit {
  loaded = false;
  races;
  totalReservations;

  constructor(private dashboardService: DashboardService, private util: UtilityService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.loadData();
  }

  private async loadData() {
    let empty = [];
    const result: any = await this.dashboardService.getDashboardData();

    result.data.sort(this.util.sortEmptyRaces(true));
    Object.keys(result.data).forEach((index: any) => {
      if(result.data[index].reservations.length > 0) {
        result.data[index].totalRunners = this.getTotalRunners(result.data[index].reservations);
        result.data[index].totalReservations = this.getTotalReservations(result.data[index].reservations, result.data[index].totalRunners);
      } else {
        result.data[index].totalRunners = null;
        result.data[index].totalReservations = null;
      }
    });

    this.races = result.data;
    this.loaded = true;
  }

  isPaid(status) {
    return status ? 'primary' : 'warn';
  }

  goToReservations(reservation) {
    const navigationExtras: NavigationExtras = { state: reservation };
    this.router.navigate(['/organizer/reservations/details'], navigationExtras);
  }

  getTotalReservations(reservations, runners) {
    let total = 0;

    reservations.forEach(reservation => {
      total += reservation.reservedPlaces;
    });

    return total - runners;
  }

  getTotalRunners(reservations) {
    let total = 0;

    reservations.forEach(reservation => {
      total += reservation.reservedRunners;
    });

    return total;
  }
}
