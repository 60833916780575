import { UtilityService } from './../../../core/Services/utility.service';
import { Component, OnInit, Optional, Inject, Input } from '@angular/core';
import { ErrorStateMatcher, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AuthenticationService } from '@core/Services/authentication.service';
import { RacesService } from '@core/Services/Data/races.service';
import { LookupsService } from '@core/Services/Data/lookups.service';
import { DialogComponent } from '../dialog.component';
import { SnackbarService } from '@core/Services/snackbar.service';
import { Subject, Observable } from 'rxjs';
import { IRace } from '@core/Models/IRace';
import { IResult } from '@core/Models/IResult';
import * as moment from 'moment';
import { FormErrorStateMatcher } from '@components/shared/form-error-state-matcher/form-error-state-matcher';
import { InventoryService } from '@core/Services/Data/inventory.service';



export const DATE_FORMAT = {
  parse: {
    dateInput: 'L'
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD.MM.YYYY'
  }
};

@Component({
  selector: 'app-race-add',
  templateUrl: './race-add.component.html',
  styleUrls: ['./race-add.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ]
})
export class RaceAddComponent implements OnInit {
  submitted = false;
  logo;
  role;
  matcher;
  selectedRaceId;
  isExpanded = false;
  edit = false;
  logoError = false;
  inventoryError = false;
  inventories = new Subject<string>();
  private selectedInventories = {
    data: []
  };
  public raceAdd: FormGroup;
  public organizer = {
    list: [],
    filtered: []
  }
  public dataToEdit: IRace | null;

  dateErrors = {
    startingDate: [],
    applicationStart: [],
    applicationEnd: [],
    orderEnd: []
  };


  constructor(private auth: AuthenticationService,
    private raceService: RacesService,
    private lookupService: LookupsService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DialogComponent>,
    private snackBarService: SnackbarService,
    private inventoryService: InventoryService,
    private util: UtilityService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: IRace) {
    this.dataToEdit = dialogData;
  }

  ngOnInit() {
    this.validation();
    this.role = this.auth.getRole();

    if (this.dataToEdit && this.dataToEdit.id) {
      this.selectedRaceId = this.dataToEdit.id;
      this.populate(this.dataToEdit);
      this.isExpanded = true;
    }

    if (this.role == 'organizer') {
      this.raceAdd.controls['organizer'].disable();
      return;
    }
    this.loadData();
  }

  async loadData() {
    const lookups: any = await this.lookupService.getMultipleLookup('organizer');

    if (lookups.hasOwnProperty('message')) {
      this.snackBarService.open(lookups.message.toString());
      return false;
    }

    this.organizer.list = lookups.data.organizer;
    this.organizer.filtered = lookups.data.organizer;
  }

  private async populate(data: IRace) {
    this.form.organizer.setValue(data.organizer);
    this.form.location.setValue(data.location);
    this.form.name.setValue(data.name);
    this.form.startplaces.setValue(data.startplaces);
    this.form.startingDate.setValue(data.startingDate);
    this.form.applicationStart.setValue(data.applicationStart);
    this.form.applicationEnd.setValue(data.applicationEnd);
    this.form.orderEnd.setValue(data.orderEnd);

    this.raceAdd.controls['startingDate'].disable();
    this.raceAdd.controls['applicationStart'].disable();
    this.raceAdd.controls['applicationEnd'].disable();
    this.raceAdd.controls['orderEnd'].disable();

    this.edit = true;
  }


  private validation() {
    this.raceAdd = this.formBuilder.group({
      organizer: ['', Validators.required],
      location: ['', Validators.required],
      name: ['', Validators.required],
      startplaces: ['', Validators.required],
      startingDate: ['', Validators.required],
      applicationStart: ['', Validators.required],
      applicationEnd: ['', Validators.required],
      orderEnd: ['', Validators.required],
    });

    this.matcher = new FormErrorStateMatcher();
  }

  get form() { return this.raceAdd.controls; }


  async onClickSubmit(data: any) {
    let result: IResult = {
      data: {},
      errors: []
    };

    if (this.selectedInventories.data.length < 1 && !this.edit) {
      this.inventoryError = true;
    }

    for (const control in this.form) {
      if (this.form[control].invalid) {
        return;
      }
    }

    for (let index in this.dateErrors) {
      if (this.dateErrors[index].length > 0) {
        return;
      }
    }

    if (!this.logo) {
      this.logoError = true;
    }

    if (this.inventoryError || this.logoError) {
      return;
    }
    this.submitted = true;

    data['logo'] = this.logo;

    if (this.edit) {
      data.id = this.dataToEdit.id;
      result = await this.raceService.editRace(data);
    } else {
      result = await this.raceService.addNewRace(data, this.selectedInventories);
    }

    if (result && result.errors.length > 0) {
      this.snackBarService.open("Dogodila se pogreška. Probajte ponovno učitati stranicu! ");
      this.submitted = false;
      return;
    }

    if (result && !this.edit) {
      this.snackBarService.open("Uspješno ste dodali trku - " + data.name + ". Lokacija: " + data.location);
      this.dialogRef.close(true);
    }

    if (this.edit) {
      this.snackBarService.open("Uspešno ste izmenili utrku - " + data.name + " (" + data.location + ") ");
      this.dialogRef.close(true);
    }

  }

  optionFilter(value, option: string) {
    if (option === 'organizer') {
      this.organizer.filtered = this.organizerFilterOptions(value, this.organizer.list);
    }
  }

  organizerFilterOptions(value, list) {
    let filter = value.toLowerCase();
    return list.filter(option => option.teamName.toLocaleLowerCase().startsWith(filter));
  }

  getInventories($event) {
    if (this.inventoryError) {
      this.inventoryError = false;
    }
    this.selectedInventories = $event;
    this.inventories.next($event);
  }

  getMinDate() {
    if (!this.edit) {
      return new Date();
    }
  }

  prepareLogo($event) {
    if ($event) {
      this.logo = $event;
      this.logoError = false;
    } else {
      this.logoError = true;
    }
  }

  validateDates(formControlName, event) {
    this.dateErrors.startingDate = [];
    this.dateErrors.applicationStart = [];
    this.dateErrors.applicationEnd = [];
    this.dateErrors.orderEnd = [];

    let startingDate = this.form.startingDate.value;
    let applicationStart = this.form.applicationStart.value;
    let applicationEnd = this.form.applicationEnd.value;
    let orderEnd = this.form.orderEnd.value;

    if (moment(startingDate).isSameOrBefore(applicationStart)) {
      this.dateErrors.startingDate.push('Datum početka utrke ne smije biti jednak ili manji od datuma početka prijava.');
    }
    if (moment(startingDate).isSameOrBefore(applicationEnd)) {
      this.dateErrors.startingDate.push('Datum početka utrke ne smije biti jednak ili manji od datuma kraja prijava.');
    }
    if (moment(startingDate).isSameOrBefore(orderEnd)) {
      this.dateErrors.startingDate.push('Datum početka utrke ne smije biti jednak ili manji od roka plaćanja.');
    }
    if (moment(applicationStart).isSameOrAfter(startingDate)) {
      this.dateErrors.applicationStart.push('Datum početka prijava ne smije biti jednak ili veći od datuma početka utrke.');
    }
    if (moment(applicationStart).isSameOrAfter(applicationEnd)) {
      this.dateErrors.applicationStart.push('Datum početka prijava ne smije biti jednak ili veći od datuma kraja prijava.');
    }
    if (moment(applicationStart).isSameOrAfter(orderEnd)) {
      this.dateErrors.applicationStart.push('Datum početka prijava ne smije biti jednak ili veći od datuma roka plaćanja.');
    }
    if (moment(applicationEnd).isSameOrAfter(startingDate)) {
      this.dateErrors.applicationEnd.push('Datum kraja prijava ne smije biti jednak ili veći od datuma početka utrke.');
    }
    if (moment(applicationEnd).isSameOrAfter(orderEnd)) {
      this.dateErrors.applicationEnd.push('Datum kraja prijava ne smije biti jednak ili veći od roka plaćanja.');
    }
    if (moment(applicationEnd).isSameOrBefore(applicationStart)) {
      this.dateErrors.applicationEnd.push('Datum kraja prijava ne smije biti jednak ili manji od datuma početka prijava.');
    }
    if (moment(orderEnd).isSameOrAfter(startingDate)) {
      this.dateErrors.orderEnd.push('Rok plaćanja ne smije biti jednak ili veći od datuma početka utrke');
    }
    if (moment(orderEnd).isBefore(applicationEnd)) {
      this.dateErrors.orderEnd.push('Rok plaćanja ne smije biti prije datuma kraja prijava.');
    }
    if (moment(orderEnd).isSameOrBefore(applicationStart)) {
      this.dateErrors.orderEnd.push('Rok plaćanja ne smije biti jednak ili manji od datuma početka prijava');
    }
  }


}
