import { SnackbarService } from '@core/Services/snackbar.service';
import { AuthenticationService } from '@core/Services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { RedirectService } from '@core/Services/redirect.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private auth: AuthenticationService, private redirect: RedirectService, private snackbarService: SnackbarService) { }

  public username: string;
  public password: string;
  public rememberMe: boolean;
  public errors: string;
  public isDisabled: boolean;
  public clicked;


  ngOnInit() {
    if(this.auth.loggedIn())
    {
      this.redirect.dashboard(this.auth.getCurrentUser());
    }
  }

  login() {
    this.isDisabled = true;
    const response = this.auth.login(this.username, this.password, this.rememberMe).then((res: any) => {
      if (res.errors.length > 0) {
        this.snackbarService.open('Došlo je do pogreške pri logiranju. Provjerite svoje podatke i link stranice na koju se logirate. Ako se greška ponovi, kontaktirajte administratora stranice.');
        this.isDisabled = false;
      } else {
        this.redirect.dashboard(this.auth.getCurrentUser());
      }
    });
  }
}
