import { AuthenticationService } from './../../../core/Services/authentication.service';
import { RacesService } from './../../../core/Services/Data/races.service';
import { CaptainsService } from './../../../core/Services/Data/captains.service';
import { ReservationsService } from './../../../core/Services/Data/reservations.service';
import { UtilityService } from './../../../core/Services/utility.service';
import { RunnersService } from '@core/Services/Data/runners.service';
import { SnackbarService } from '@core/Services/snackbar.service';
import { LookupsService } from '@core/Services/Data/lookups.service';
import { Component, OnInit, ViewChild, Optional, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatStepper } from '@angular/material/stepper';
import { DialogComponent } from '../dialog.component';
import { IReservation } from '@core/Models/IReservation';
import { IResult } from '@core/Models/IResult';
import { FormErrorStateMatcher } from '@components/shared/form-error-state-matcher/form-error-state-matcher';


@Component({
  selector: 'app-reservation-add',
  templateUrl: './reservation-add.component.html',
  styleUrls: ['./reservation-add.component.scss']
})
export class ReservationAddComponent implements OnInit {
  teamName = '';
  raceName = '';
  raceStartingDate = '';
  isDisabled;
  role;
  matcher;
  raceId;
  amount;
  showRunners = false;
  showRunnersFiltered = false;
  edit = false;
  preselected = false;
  firstPageCompleted = false;
  secondPageCompleted = false;
  dataToEdit: IReservation;

  public filteredRunners;
  public reservationAdd: FormGroup;
  public setColumns: string[] = [
    'checkbox',
    'name',
    'lastName',
    'pin',
  ];

  public infoColumns: string[] = [
    'position',
    'name',
    'lastName'
  ];

  public selectedRunners = {
    filtered: []
  };


  public captain = {
    all: [],
    list: [],
    filtered: []
  }
  public race = {
    all: [],
    list: [],
    filtered: []
  }
  public organizer = {
    all: [],
    list: [],
    filtered: []
  }

  public currency;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;


  constructor(
    private lookupService: LookupsService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private runnerService: RunnersService,
    private reservationService: ReservationsService,
    private dialogRef: MatDialogRef<DialogComponent>,
    private captainService: CaptainsService,
    private raceService: RacesService,
    private auth: AuthenticationService,
    private util: UtilityService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: IReservation) {
      this.dataToEdit = dialogData;
      this.role = this.auth.getRole();
  }


  ngOnInit() {
    this.validation();
    this.loadData().then(res => {

      if (this.dataToEdit) {
        this.populate(this.dataToEdit);
      }
    });
  }

  private async setCaptain() {
    let response: any = await this.captainService.getCurrentCaptain();

    let filteredCaptain = this.captain.list.filter(
      cap => cap.id == response.data.id
    );
    this.form.captain.setValue(filteredCaptain[0]);
    this.reservationAdd.controls['captain'].disable();
    this.preselected = true;
  }


  private async populate(data: IReservation) {
    if (data && data.raceId) {
      let filteredRace = this.race.list.filter(race => {
          return race.id == data.raceId;
      });

      this.form.race.setValue(filteredRace[0]);
    }
  }

  async loadData() {
    let lookupString = '';
    if(this.role == "admin") {
      lookupString = 'organizer,captain,race';
    } else {
      lookupString = 'captain,race';
    }

    const lookups: any = await this.lookupService.getMultipleLookup(lookupString);
    
    if(lookups && this.util.isEmptyObject(lookups.data)) {
      this.snackbarService.open('Nešto je pošlo po krivu. Molimo ponovno učitajte stranicu!');
      return false;
    }

    if(lookups.data.race.length < 1) {
      this.reservationAdd.controls['race'].disable();
      this.reservationAdd.controls['captain'].disable();
      this.reservationAdd.controls['reservationNumber'].disable();
      this.isDisabled = true;
      return;
    }

    this.captain.all = lookups.data.captain;
    this.race.all = lookups.data.race;
    this.setDropdowns(lookups);

    if(this.role == 'captain') {
      this.setCaptain();
    }
  }
  public findCaptain(organizer) {
    this.currency = organizer.currency;
    this.captain.filtered  = this.captain.list.filter(cap => 
      cap.organizerId == organizer.id
    );
    this.race.filtered  = this.race.list.filter(rac => 
      rac.organizerId == organizer.id
    );
    
  }
  private setDropdowns(lookups) {
    if(this.role == 'admin') {
      this.organizer.all = lookups.data.organizer;
      this.organizer.list = lookups.data.organizer;
      this.organizer.filtered = lookups.data.organizer;

      this.captain.all = lookups.data.captain;
      this.captain.list = lookups.data.captain;
      this.captain.filtered = lookups.data.captain;

      this.race.all = lookups.data.race;
      this.race.list = lookups.data.race;
      this.race.filtered = lookups.data.race;

      // this.form.organizer.setValue(this.organizer.all[0]);
      // this.filterRacesAndCaptains(this.organizer.all[0]);
    }
    if(this.role == 'organizer') {
      this.organizer.all = lookups.data.organizer;
      this.organizer.list = lookups.data.organizer;
      this.organizer.filtered = lookups.data.organizer;

      this.captain.all = lookups.data.captain;
      this.captain.list = lookups.data.captain;
      this.captain.filtered = lookups.data.captain;

      this.race.all = lookups.data.race;
      this.race.list = lookups.data.race;
      this.race.filtered = lookups.data.race;
    }
    if(this.role == 'captain') {
      this.organizer.all = lookups.data.organizer;
      this.organizer.list = lookups.data.organizer;
      this.organizer.filtered = lookups.data.organizer;

      this.captain.all = lookups.data.captain;
      this.captain.list = lookups.data.captain;
      this.captain.filtered = lookups.data.captain;

      this.race.all = lookups.data.race;
      this.race.list = lookups.data.race;
      this.race.filtered = lookups.data.race;
    }
   }

  // private filterRacesAndCaptains(organizer) {

  // }

  private validation() {
    this.reservationAdd = this.formBuilder.group({
      organizer: ['', Validators.required],
      captain: ['', Validators.required],
      race: ['', Validators.required],
      reservationNumber: ['', Validators.required]
    });

    this.matcher = new FormErrorStateMatcher();
  }

  get form() { return this.reservationAdd.controls; }

  optionFilter(value, option: string) {
    if (option === 'captain') {
      this.captain.filtered = this.captainFilterOptions(value, this.captain.list);
    }
    if (option === 'race') {
      this.race.filtered = this.raceFilterOptions(value, this.race.list);
    }
  }
  captainFilterOptions(value, list) {
    let filter = value.toLowerCase();
    return list.filter(option => option.teamName.toLocaleLowerCase().startsWith(filter));
  }
  raceFilterOptions(value, list) {
    let filter = value.toLowerCase();
    return list.filter(option => option.name.toLocaleLowerCase().startsWith(filter));
  }

  async nextPage() {
    let response;
    const valid = this.reservationAdd.valid;

    if(!valid) {
      return;
    }

    const captainId = this.form.captain.value.id;
    const raceId = this.form.race.value.id;
    if (!this.firstPageCompleted)
    {
      response = await this.runnerService.getAvailableRunnersByCaptainId(captainId, raceId);
      this.firstPageCompleted = true;
      this.selectedRunners = response.data;
      this.showRunners = true;
    }

    this.stepper.selectedIndex = 1;
    this.stepper._stateChanged();
  }

  lastPage() {
    if(this.filteredRunners){
      this.selectedRunners.filtered = this.filteredRunners;
    }

    this.teamName = this.form.captain.value.teamName;
    this.raceName = this.form.race.value.name;
    this.raceStartingDate = this.form.race.value.startingDate;

    this.raceId = this.form.race.value.id;
    this.amount = this.form.reservationNumber.value;
    this.secondPageCompleted = true;
    this.showRunnersFiltered = true;

    this.stepper.selectedIndex = 2;
    this.stepper._stateChanged();
  }

  backPage() {
    if(this.secondPageCompleted) {
      this.secondPageCompleted = false;
    } else {
      this.firstPageCompleted = false;
    }
  }

  getSelectedRunners($event) {
      this.filteredRunners = $event;
  }

  async onClickSubmit(data) {
    let result: IResult;

    if(this.auth.getRole() == 'captain')
    {
      data = this.reservationAdd.getRawValue();
    }

    data.runners = this.selectedRunners.filtered;
    result = await this.reservationService.addNewReservation(data);

    if(result && result.errors.length > 0) {
      this.snackbarService.open("Greška: " + result.errors);
    }

    if(result && result.data)
    {
      this.snackbarService.open("Uspešno ste dodali rezervaciju za utrku - " + data.race.name);
      this.dialogRef.close({ result: true });
    }
  }
}



