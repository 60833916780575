import { IReservation } from './../../core/Models/IReservation';
import { CaptainsService } from './../../core/Services/Data/captains.service';
import { UtilityService } from './../../core/Services/utility.service';
import { SnackbarService } from '@core/Services/snackbar.service';
import { ModalService } from '@core/Services/modal.service';
import { AuthenticationService } from '@core/services/authentication.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { RacesService } from '@core/Services/Data/races.service';
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-races',
  templateUrl: './races.component.html',
  styleUrls: ['./races.component.scss']
})
export class RacesComponent implements OnInit {
  public dataSource: any;
  public btnColor: any;
  route: string;
  maxScroll: any | undefined;
  private card: string;
  isSelected: boolean;
  isOrganizer: boolean = false;
  totalRunners: number;
  arrowLeftOpacity = '0.2';
  arrowRightOpacity = '0.8';
  @Input() showDetail = true;
  @Output() race = new EventEmitter<string>();
  @ViewChild('container', { static: false })  public container: ElementRef<any>;
  @ViewChild('arrowLeft', { static: false })  public arrowLeft: ElementRef<any>;
  @ViewChild('arrowRight', { static: false })  public arrowRight: ElementRef<any>;


  constructor(private auth: AuthenticationService, private racesService: RacesService, private router: Router, private dialog: ModalService,
    private snackbarService: SnackbarService, private util: UtilityService, private raceService: RacesService, private activatedRoute: ActivatedRoute
    ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.route = val.url;
      }
    });
  }

  ngOnInit() {
    this.loadData();
  }

  public refresh() {
    this.loadData();
  }

  private async loadData() {
    let response;

    if(!this.showDetail) {
      if(this.auth.getRole() == "captain") {
        response = await this.racesService.getAvailableRaces();
      }
      if(this.auth.getRole() == "organizer") {
        response = await this.racesService.getAllRacesByOrganizer();
      }
      if(this.auth.getRole() == "admin") {
        response = await this.racesService.getAllRaces();
      }
    } else {
      if(this.auth.getRole() == "admin") {
        response = await this.racesService.getAllRaces();
      } else {
      response = await this.racesService.getAllRacesByOrganizer();
      }
    }

    if (response && response.errors.length > 0) {
      this.snackbarService.open('Dogodila se greška. Molimo učitajte stranicu ponovno.');
    } else {
      this.dataSource = response.data;

      if(this.auth.getRole() == "organizer") {
        this.isOrganizer = true;
      }
    }
  }

  public selectRace(race, i, event) {
    if(this.showDetail) {
      return;
    }

    let currentCard = 'race-' + i;
    let element = document.getElementById(currentCard);

    if(this.isSelected)
    {
      this.isSelected = false;
      element = document.getElementById(this.card);
      element.classList.remove('selected');

      if(this.card !== currentCard) {
        this.selectRace(race, i, event);
      } else {
        this.race.emit(null);
      }
    } else {
      this.isSelected = true;
      element.classList.add('selected');
      this.card = currentCard;
      this.race.emit(race);
    }
  }

  public async setReservation(raceId) {
    let data: IReservation = {
      raceId: raceId,
    }

    this.dialog.openReservationAdd(data).subscribe((res: any) => {
      if(!res || res == undefined || res.result === false)
      {
        return;
      }

      this.router.navigate(["/captain/reservations"]);
    });
  }

  editRace(race) {
    this.dialog.openRaceAdd(race).subscribe((res: any) => {
      if(!res || res == undefined)
      {
        return;
      }

      this.loadData();
    });
  }

  getRunningOrder(race) {
    const navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, state: race };
    this.router.navigate(['roster'], navigationExtras);
  }

  async lockRace(race) {
    if(this.util.isEmptyObject(race))
    {
      this.snackbarService.open('Nešto je pošlo po krivu. Probajte ponovno učitati stranicu!');
    }

    let result = await this.racesService.lockRace(race);

    if(result && result.errors.length > 0)
    {
      this.snackbarService.open('Dogodila se greška tokom zaključavanja utrke. Probajte ponovno učitati stranicu!');
    }

    if(result && !this.util.isEmptyObject(result))
    {
      this.snackbarService.open('Utrka ' + race.name + ' je uspješno zaključana.');
      this.loadData();
    }
  }

  reservationAvailable(race) {
    if(moment(race.applicationEnd) < moment()) {
      return true;
    }

    if(moment(race.applicationStart) > moment()) {
      return true;
    }

    if(race.locked == 1) {
      return true;
    }

    if(race.totalReservations == race.startplaces) {
      return true;
    }

    return false;
  }

  scrollRight() {
    if(this.maxScroll == undefined) {
      this.getMaxScroll();
    }
    this.container.nativeElement.scrollLeft += 300;
    this.arrowLeftOpacity = (this.container.nativeElement.scrollLeft == 0) ? '0.2' : '0.8';
    this.arrowRightOpacity = (this.container.nativeElement.scrollLeft >= this.maxScroll) ? '0.2' : '0.8';
  }

  scrollLeft() {
    if(this.maxScroll == undefined) {
      this.getMaxScroll();
    }

    this.container.nativeElement.scrollLeft -= 300;
    this.arrowLeftOpacity = (this.container.nativeElement.scrollLeft == 0) ? '0.2' : '0.8';
    this.arrowRightOpacity = (this.container.nativeElement.scrollLeft == this.maxScroll) ? '0.2' : '0.8';
  }

  getMaxScroll() {
    let scroll = this.container.nativeElement.scrollWidth;
    let client = this.container.nativeElement.clientWidth;
    this.maxScroll = scroll - client;
  }
}
