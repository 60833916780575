import { AuthenticationService } from '@core/Services/authentication.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { UtilityService } from '../utility.service';
import { first } from 'rxjs/operators';
import { IResult } from '@core/Models/IResult';


@Injectable({
  providedIn: 'root'
})
export class RacesService {

  constructor(private api: ApiService, private util: UtilityService, private auth: AuthenticationService) {
    this.init();
  }

  init() {
    this.auth.isAuthorized();
  }

  public async addNewRace(data, inventories) {
    let result: IResult = {
      data: {},
      errors: []
    };

    data['inventories'] = inventories['data'];
    data['inventoryDescription'] = inventories['desc'];
    data['inventoryType'] = inventories['type'];
    data['inventoryName'] = inventories['name'];

    try {
      result.data = await this.api.addNewRace(data).pipe(first()).toPromise();
    } catch (e) {
      result.errors = [e.error];
    }


    return result;
  }


  public async editRace(data): Promise<IResult> {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.editRace(data).pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    return result;
  }

  public async getAllRaces(): Promise<IResult> {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.getAllRaces().pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    result.data = this.util.keysToCamelCase(result.data);
    return result;
  }

  public async getById(raceId): Promise<IResult> {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.getRaceById(raceId).pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    result.data = this.util.keysToCamelCase(result.data);
    return result;
  }

  public async getAvailableRaces() {
    let races: IResult = {
      data: {},
      errors: []
    };

    try {
      races.data = await this.api.getAvailableRaces().pipe(first()).toPromise();
    } catch (e) {
      races.errors = [e.error];
    }

    races.data = this.util.keysToCamelCase(races.data);
    return races;
  }

  public async getAllRacesByOrganizer() {
    let races: IResult = {
      data: {},
      errors: []
    };

    try {
      races.data = await this.api.getAllRacesByOrganizer().pipe(first()).toPromise();
    } catch (e) {
      races.errors = [e.error];
    }

    races.data = this.util.keysToCamelCase(races.data);
    return races;
  }


  public async getRaceNameById(raceId) {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.getRaceName(raceId).pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    result.data = this.util.keysToCamelCase(result.data);
    return result;
  }

  public async lockRace(race) {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.lockRace(race).pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    return result;
  }

  public async getEntryList(raceId) {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.getEntryList(raceId).pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    result.data = this.util.keysToCamelCase(result.data);

    return result;
  }

  public async getRaceLogo(raceId) {
    let result: IResult = {
      data: {},
      errors: []
    };

    try {
      result.data = await this.api.getRaceLogo(raceId).pipe(first()).toPromise();
    } catch (e) {
      result.errors = e.error;
    }

    result.data = this.util.keysToCamelCase(result.data);
    return result;
  }

}
