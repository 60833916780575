import { CaptainAddComponent } from './../../components/dialog/captain-add/captain-add.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { OrganizerAddComponent } from '@components/dialog/organizer-add/organizer-add.component';
import { RunnerAddComponent } from '@components/dialog/runner-add/runner-add.component';
import { ReservationAddComponent } from '@components/dialog/reservation-add/reservation-add.component';
import { RaceAddComponent } from '@components/dialog/race-add/race-add.component';
import { RunnersSelectComponent } from '@components/dialog/runners-select/runners-select.component';
import { ConfirmationComponent } from '@components/dialog/confirmation/confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(public dialog: MatDialog) { }

  openOrganizerAdd(data = null) {
    const dialogRef = this.dialog.open(OrganizerAddComponent, {
      width: '50%',
      height: '80%',
      panelClass: 'full-height-dialog',
      data: data,
    });

    return dialogRef.afterClosed();
  }

  openRunnerAdd(data = null) {
    const dialogRef = this.dialog.open(RunnerAddComponent, {
      width: '50%',
      height: '80%',
      panelClass: 'full-height-dialog',
      data: data,
    });

    return dialogRef.afterClosed();
  }
  openRunnerSelect(data = null) {
    const dialogRef = this.dialog.open(RunnersSelectComponent, {
      width: '50%',
      height: '80%',
      panelClass: 'full-height-dialog',
      data: data,
    });

    return dialogRef.afterClosed();
  }

  openCaptainAdd(data = null) {
    const dialogRef = this.dialog.open(CaptainAddComponent, {
      width: '50%',
      height: '80%',
      panelClass: 'full-height-dialog',
      data: data,
    });

    return dialogRef.afterClosed();
  }

  openReservationAdd(data = null) {
    const dialogRef = this.dialog.open(ReservationAddComponent, {
      width: '50%',
      height: '80%',
      panelClass: 'full-height-dialog',
      data: data,
    });

    return dialogRef.afterClosed();
  }

  openRaceAdd(data = null) {
    const dialogRef = this.dialog.open(RaceAddComponent, {
      width: '50%',
      height: '80%',
      panelClass: 'full-height-dialog',
      data: data,
    });

    return dialogRef.afterClosed();
  }

  openConfirmationDialog(data = null) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '30%',
      height: '42%',
      panelClass: 'full-height-dialog',
      data: data,
    });

    return dialogRef.afterClosed();
  }
}
