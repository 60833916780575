import { throwToolbarMixedModesError } from '@angular/material';
import { AuthenticationService } from '@core/services/authentication.service';
import { DashboardService } from './../../../core/Services/Data/dashboard.service';
import { Component, OnInit, ViewChild, Input, AfterViewInit, OnChanges } from '@angular/core';


@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnChanges {
  isLoading = false;
  @Input() chartData: any;

  title = 'Nema naslova';
  type = 'PieChart';
  width = 400;
  height = 250;

  data = [];

  columnNames = ['Browser', 'Percentage'];

  options = {
    pieHole: 0.4,
    colors: ['#00385D', '#F15A24', '#ABE0F9'],
    pieSliceText: 'value'
  };



  constructor(private dashboardService: DashboardService, private auth: AuthenticationService) { }

  ngOnChanges() {
    this.isLoading = true;
    this.title = this.chartData.name + ' (' + this.chartData.location + ') ';
    this.data = [
      ['Prijavljeni trkači', this.chartData.totalRunners],
      ['Preostalo rezerviranih mjesta', this.chartData.totalReservations]
    ];

    if(this.auth.getRole() == 'organizer' || this.auth.getRole() == 'admin') {
      let remainingStartplaces = this.chartData.startplaces - (this.chartData.totalRunners + this.chartData.totalReservations);
      this.data.push(['Preostalo startnih mjesta', remainingStartplaces]);
    }

    if(this.data) {
      this.isLoading = false;
    }
  }

}
