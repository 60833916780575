import { SnackbarService } from './snackbar.service';
import { Injectable, ElementRef } from '@angular/core';
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  public baseUrl = 'http://businessrun.test/public/api/';
  // public baseUrl = 'http://localhost:9000/public/api/';
  public subdomain: string;

  constructor(private snackbarService: SnackbarService) { }

  public getSubdomain() {
    const domain = window.location.hostname;
    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
      this.subdomain = '';
    } else {
      this.subdomain = domain.split('.')[0];
    }
    return this.subdomain;
  }

  public getUrl() {
    const domain = window.location.origin;
    return domain;
  }

  public isEmptyObject(obj: object, strict: boolean = false) {
    let isEmpty = true;

    if(obj == null || obj == undefined) {
      return isEmpty;
    }

    // if strict true then return false on first null value
    if (strict) {
      for (const key in obj) {
        if (obj[key] !== null && obj[key] !== '') {
          isEmpty = false;
          break;
        } else {
          isEmpty = true;
          break;
        }
      }
    } else {
      isEmpty = Object.keys(obj).every((x) => obj[x] === '' || obj[x] === null || obj[x].length < 1);
    }

    return isEmpty;
  }

  public keysToCamelCase(jsonObj) {
    const camelCaseObj: Array<object> = [];
    let prep = {};
    // tslint:disable-next-line: forin
    for (const key in jsonObj) {
      if (parseInt(key) || parseInt(key) == 0) {
        for (const param in jsonObj[key]) {
          if (param) {
            const camelCase = this.toCamelCase(param);
            prep[camelCase] = jsonObj[key][param];
          }
        }
      } else {
        camelCaseObj[this.toCamelCase(key)] = jsonObj[key];
      }
      if (!this.isEmptyObject(prep, true)) {
        camelCaseObj.push(prep);
        prep = {};
      }
    }
    return camelCaseObj;
  }

  public isObject(obj) {
    return obj === Object(obj) && !isArray(obj) && typeof obj !== 'function';
  }

  private toCamelCase(s) {
    return s.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  }

  public fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");

    // Create new textarea and set styles
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      this.snackbarService.open("Mikrosajt kopiran u memoriju!");
      document.body.removeChild(textArea);
      return true;
    } catch (err) {
      this.snackbarService.open("Došlo je do pogreške! Probajte ponovno učitati stranicu.");
      document.body.removeChild(textArea);
      return false;
    }

  }

  public copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      return this.fallbackCopyTextToClipboard(text);
    }
    navigator.clipboard.writeText(text).then(() => {
      this.snackbarService.open("Mikrosajt kopiran u memoriju!");
      return true;
    }, function(err) {
      this.snackbarService.open("Došlo je do pogreške! Probajte ponovno učitati stranicu.");
      return false;
    });
  }

  public async encodeImage(logo) {
    return new Promise((resolve, reject) => {
      if(logo) {
        var reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(logo);
      }
    });
  }

  encodeURIData(object) {
      let params = JSON.stringify(object);
      params = encodeURIComponent(params);
      params = btoa(params);

      return params;
  }

  sortEmptyRaces(ascending) {
    return function (a,b) {
      if(a.reservations.length == b.reservations.length) {
        return 0;
      }
      else if (a.reservations.length == 0) {
        return 1;
      }
      else if (b.reservations.length == 0) {
        return -1;
      }
      else if (ascending) {
        return a.reservations.length < b.reservations.length ? -1 : 1;
      }
      else {
        return a.reservations.length < b.reservations.length ? 1 : -1
      }
    }
  }

  sortEmptyReservations(ascending) {
    return function (a,b) {
      if(a.length == b.length) {
        return 0;
      }
      else if (a.length == 0) {
        return 1;
      }
      else if (b.length == 0) {
        return -1;
      }
      else if (ascending) {
        return a.length < b.length ? -1 : 1;
      }
      else {
        return a.length < b.reservations.length ? 1 : -1
      }
    }
  }
}

