import { Component, OnInit } from '@angular/core';
import { DashboardService } from '@core/Services/Data/dashboard.service';
import { UtilityService } from '@core/Services/utility.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss']
})
export class DashboardAdminComponent implements OnInit {
  dataSource;
  loaded = false;
  totalReservations;

  constructor(private dashboardService: DashboardService, private util: UtilityService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.loadData();
  }

  private async loadData() {
    let empty = [];
    let noRaces = [];
    let finalResult = [];
    let result: any = await this.dashboardService.getDashboardData();

    Object.keys(result.data).forEach((i: any) => {
      if(i > result.data.length) {
        return;
      }
      if(result.data[i].races.length > 0) {
        result.data[i].races = this.util.keysToCamelCase(result.data[i].races);
        result.data[i].races.sort(this.util.sortEmptyRaces(true));
        result.data[i].races.forEach((race: any, index: any) => {
          race.reservations = this.util.keysToCamelCase(race.reservations);
          if (race.reservations.length > 0) {
            race.totalRunners = this.getTotalRunners(race.reservations);
            race.totalReservations = this.getTotalReservations(race.reservations, race.totalRunners);
          } else {
            race.totalRunners = null;
            race.totalReservations = null;
          }
        });
      }
    });

    this.dataSource = result.data;
    this.loaded = true;
  }

  isPaid(status) {
    return status ? 'primary' : 'warn';
  }

  goToReservations(reservation) {
    const navigationExtras: NavigationExtras = { state: reservation };
    this.router.navigate(['/admin/reservations/details'], navigationExtras);
  }

  getTotalReservations(reservations, runners) {
    let total = 0;

    reservations.forEach(reservation => {
      total += reservation.reservedPlaces;
    });
    return total - runners;
  }

  getTotalRunners(reservations) {
    let total = 0;

    reservations.forEach(reservation => {
      total += reservation.reservedRunners;
    });

    return total;
  }
}
