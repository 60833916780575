import { ICaptain } from '@core/Models/ICaptain';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CaptainMapper {


  constructor() {
  }

  fromArray(data: Array<any>): ICaptain {
    const captain: ICaptain = {
      id: data['id'] ? data['id'] : null,
      name: data['name'] ? data['name'] : '',
      lastName: data['lastName'] ? data['lastName'] : '',
      email: data['email'] ? data['email'] : '',
      companyName: data['companyName'] ? data['companyName'] : '',
      pin: data['pin'] ? data['pin'] : 0,
      city: data['city'] ? data['city'] : '',
      teamName: data['teamName'] ? data['teamName'] : '',
      phone: data['phone'] ? data['phone'] : '',
      address: data['address'] ? data['address'] : '',
      postcode: data['postcode'] ? data['postcode'] : null,
      totalEmployees: data['totalEmployees'] ? data['totalEmployess'] : null,
      business: data['business'] ? data['business'] : '',
      customWelcome: data['customWelcome'] ? data['customWelcome'] : null,
      billingCompany: data['billingCompany'],
      billingCity: data['billingCity'],
      billingAddress: data['billingAddress'],
      billingPostcode: data['billingPostcode'],
      billingPhone: data['billingPhone'],
      billingPin: data['billingPin']
    };

    return captain;
  }
}
